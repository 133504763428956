import React from "react"
import { useNavigate } from "react-router-dom"
import { Box, Image, Text } from "@chakra-ui/react"

const Post = ({ url, image, alt, title, description }) => {
  const navigate = useNavigate()

  const handlePostClick = () => {
    navigate(`/${url}`)
  }

  return (
    <Box
      textAlign={"left"}
      cursor={"pointer"}
      onClick={handlePostClick}
      flex={"1 1 25%"}
    >
      <Image src={image} width={"100%"} borderRadius={"12px"} alt={alt} />
      <Text as={"h2"} fontSize={"14px"} fontWeight={"bold"} mt={4}>
        {title}
      </Text>
      <Text fontSize={"14px"} mt={"6px"}>
        {description}
      </Text>
    </Box>
  )
}

export default Post
