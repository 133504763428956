import React, { useEffect, useRef, useState } from "react"
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react"
import { COLORS } from "../../../constants/constants"

import { ReactComponent as AirplaneProgress } from "../../../img/airplane_progress_bar.svg"

const FlightLoadingProgress = ({ finishedFlightUpdate, fromCode, toCode }) => {
  const [progress, setProgress] = useState(0)
  const progressIntervalRef = useRef(null)
  const [isSmallScreen] = useMediaQuery("(max-width: 540px)")
  const [isMediumScreen] = useMediaQuery("(max-width: 1240px)")

  useEffect(() => {
    if (finishedFlightUpdate) {
      setProgress(100)
      clearInterval(progressIntervalRef.current)
    }
  }, [finishedFlightUpdate])

  useEffect(() => {
    setProgress(0)

    progressIntervalRef.current = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 0.1 : 100))
    }, 15)

    return () => {
      clearInterval(progressIntervalRef.current)
    }
  }, [])

  const dotCount = isSmallScreen ? 25 : isMediumScreen ? 50 : 100
  const responsiveProgress = isSmallScreen
    ? progress / 4
    : isMediumScreen
    ? progress / 2
    : progress

  return (
    <Flex alignItems={"center"} justifyContent={"center"} height={"40px"}>
      <Flex gap={6}>
        <Text>{fromCode}</Text>

        <Flex alignItems="center" position={"relative"}>
          <Flex gap={1}>
            {Array.from({ length: dotCount }, (_, i) => {
              return (
                <Box
                  key={i}
                  width="4px"
                  height="4px"
                  borderRadius="50%"
                  bg={i * 1 < responsiveProgress ? COLORS.red : "#6a6e8566"}
                />
              )
            })}
          </Flex>
          <Box
            position="absolute"
            left={`${progress}%`}
            transform="translateX(-50%)"
            fontSize="16px"
            color={COLORS.red}
          >
            <AirplaneProgress />
          </Box>
        </Flex>

        <Text>{toCode}</Text>
      </Flex>
    </Flex>
  )
}

export default FlightLoadingProgress
