import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  Button,
  useToast,
  Flex,
} from "@chakra-ui/react"
import { useParams, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"

import { resetPasswordConfirm } from "../services/api"
import PasswordInput from "../components/PasswordInput"
import { COLORS } from "../constants/constants"

export default function ResetPasswordConfirm() {
  const { t } = useTranslation()
  const { uid, token } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { mutateAsync: resetPasswordConfirmMutation } = useMutation({
    mutationFn: resetPasswordConfirm,
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()

  const onSubmit = async (values) => {
    try {
      await resetPasswordConfirmMutation({
        new_password: values.password,
        uid,
        token,
      })
      toast({
        position: "bottom-right",
        title: t("resetPasswordConfirm.toastSuccess"),
        status: "success",
      })
      navigate("/login")
    } catch (error) {
      setError("root.resetPasswordConfirmError", {
        type: "resetPasswordConfirmError",
        message: t("resetPasswordConfirm.generalError"),
      })
    }
  }

  return (
    <Flex
      bg={COLORS.grey}
      marginInlineStart={0}
      flexGrow={1}
      justifyContent={"center"}
    >
      <Box width={{ base: 350, sm: 400 }} p={7}>
        <Heading
          as="h1"
          pb={6}
          color={COLORS.black}
          textAlign="left"
          fontSize={"2xl"}
        >
          {t("resetPasswordConfirm.header")}
        </Heading>
        <Text fontSize={"sm"} textAlign={"left"} mb={6}>
          {t("resetPasswordConfirm.paragraph")}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors?.password}>
            <FormLabel fontSize={"xs"} fontWeight="semibold">
              {t("login.password")}
            </FormLabel>
            <PasswordInput
              name={"password"}
              register={register("password", {
                required: t("validation.required"),
              })}
            />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            <Box
              textAlign="right"
              color={COLORS.red}
              fontSize={"sm"}
              textDecoration={"underline"}
            ></Box>
          </FormControl>
          <FormControl isInvalid={errors.root?.resetPasswordConfirmError}>
            <FormErrorMessage>
              {errors.root?.resetPasswordConfirmError.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            textTransform={"uppercase"}
            type="submit"
            my={5}
            width={"100%"}
            backgroundColor={COLORS.red}
            color="white"
          >
            {t("resetPasswordConfirm.button")}
          </Button>
        </form>
      </Box>
    </Flex>
  )
}
