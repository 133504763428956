import React, { useState } from "react"
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import PasswordInput from "../../PasswordInput"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { changePassword } from "../../../services/api"

const ChangePasswordModal = ({
  isOpen,
  onOpen,
  onClose,
  onPasswordChangeSuccess,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [error, setError] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm()

  const mutation = useMutation({
    mutationFn: (formData) => changePassword(formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["me"])
      onPasswordChangeSuccess()
      reset()
      onClose()
    },
    onError: (error) => {
      if (error?.response?.data?.new_password) {
        setError(error.response.data.new_password[0])
      }

      console.error("Password reset failed:", error)
    },
  })

  const onSubmit = (formData) => {
    mutation.mutate({
      current_password: formData.currentPassword,
      new_password: formData.newPassword,
    })
  }

  const handleCloseModal = () => {
    reset()
    onClose()
    setError(null)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent
        gap={6}
        sx={{
          transform: "translate(0%,-50%)!important",
          top: "50%",
          padding: "30px",
          margin: 0,
        }}
      >
        <Text fontSize={"24px"} fontWeight={700}>
          {t("settings.resetPassword")}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody p={0}>
            <Flex
              flexDirection={"column"}
              gap={4}
              sx={{ "& > div > p": { fontSize: "12px", fontWeight: 600 } }}
            >
              <Flex gap={2} display={"flex"} flexDirection={"column"}>
                <Text>{t("settings.newPassword")}</Text>
                <PasswordInput
                  name={"newPassword"}
                  register={register("newPassword", {
                    required: t("validation.required"),
                  })}
                  fontSize={"14px"}
                />
                {errors.newPassword && (
                  <Text color={COLORS.red}>{errors.newPassword.message}</Text>
                )}
              </Flex>

              <Flex gap={2} display={"flex"} flexDirection={"column"}>
                <Text>{t("settings.confirmPassword")}</Text>
                <PasswordInput
                  name={"confirmPassword"}
                  register={register("confirmPassword", {
                    required: t("validation.required"),
                    validate: (value) =>
                      value === getValues("newPassword") ||
                      t("validation.passwordsNotMatch"),
                  })}
                  fontSize={"14px"}
                />
                {errors.confirmPassword && (
                  <Text color={COLORS.red}>
                    {errors.confirmPassword.message}
                  </Text>
                )}
              </Flex>

              <Flex gap={2} display={"flex"} flexDirection={"column"}>
                <Text>{t("settings.currentPassword")}</Text>
                <PasswordInput
                  name={"currentPassword"}
                  register={register("currentPassword", {
                    required: t("validation.required"),
                  })}
                  fontSize={"14px"}
                />
                {errors.currentPassword && (
                  <Text color={COLORS.red}>
                    {errors.currentPassword.message}
                  </Text>
                )}
              </Flex>
            </Flex>

            {error && (
              <Text color={COLORS.red} fontSize={"12px"} fontWeight={600}>
                {error}
              </Text>
            )}
          </ModalBody>

          <ModalFooter sx={{ padding: 0, marginTop: 6 }}>
            <Flex
              width={"100%"}
              gap={5}
              sx={{
                "& > button > p": {
                  textTransform: "uppercase",
                  fontSize: "14px",
                  fontWeight: 700,
                },
              }}
            >
              <Button
                type={"button"}
                width={"100%"}
                onClick={handleCloseModal}
                color={COLORS.red}
              >
                <Text>{t("settings.cancel")}</Text>
              </Button>
              <Button
                type={"submit"}
                width={"100%"}
                backgroundColor={COLORS.red}
                color={COLORS.white}
              >
                <Text>{t("settings.save")}</Text>
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default ChangePasswordModal
