import { Flex } from "@chakra-ui/react"
import Footer from "../../Footer"
import { COLORS } from "../../../constants/constants"

const AllAvailabilityLayout = ({ children, isLoading }) => {
  return (
    <Flex
      position={"relative"}
      minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
      flexGrow={1}
      flexDirection={"column"}
      justifyContent={"space-between"}
      bg={COLORS.grey}
    >
      <Flex w={"100%"} mb={"36px"} flexDirection={"column"}>
        {children}
      </Flex>
      <Footer />
    </Flex>
  )
}

export default AllAvailabilityLayout
