import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { COLORS } from "../../../constants/constants"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateUser } from "../../../services/api"

const UpdateUserForm = ({ user, handleCloseEdit }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone_number: "",
    },
    values: {
      firstName: user.first_name,
      lastName: user.last_name,
      phone_number: user.phone_number || "",
    },
  })

  const { mutateAsync: updateUserInfo } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => queryClient.invalidateQueries(["me"]),
  })

  const onSubmit = async (values) => {
    try {
      const updateValues = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phone_number,
      }

      await updateUserInfo(updateValues)

      toast({
        position: "bottom-right",
        title: "Successfully updated user data",
        status: "success",
      })
    } catch (err) {
      setError("root.updateUserError", {
        type: "updateUserError",
        message: "Error happened. Please try again.",
      })
      toast({
        position: "bottom-right",
        title: "Error updating user data",
        status: "error",
      })
    }

    handleCloseEdit()
  }

  return (
    <form
      id={"user-settings-form"}
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      <Flex flexDirection={"column"} gap={4}>
        <FormControl
          isInvalid={!!errors?.firstName}
          gap={"6px"}
          display={"flex"}
          flexDirection={"column"}
        >
          <FormLabel
            fontSize={"xs"}
            fontWeight={"semibold"}
            whiteSpace={"nowrap"}
            m={0}
          >
            {t("register.firstName")}
          </FormLabel>
          <Input
            {...register("firstName", {
              required: t("validation.required"),
            })}
            bg={COLORS.white}
            height={9}
          />
          <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!errors?.lastName}
          gap={"6px"}
          display={"flex"}
          flexDirection={"column"}
        >
          <FormLabel
            fontSize={"xs"}
            fontWeight={"semibold"}
            whiteSpace={"nowrap"}
            m={0}
          >
            {t("register.lastName")}:
          </FormLabel>
          <Input
            {...register("lastName", {
              required: t("validation.required"),
            })}
            bg={COLORS.white}
            height={9}
          />
          <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!errors?.phone_number}
          gap={"6px"}
          display={"flex"}
          flexDirection={"column"}
        >
          <FormLabel
            fontSize={"xs"}
            fontWeight="semibold"
            whiteSpace={"nowrap"}
            m={0}
          >
            {t("login.mobile")}:
          </FormLabel>

          <Flex flexDirection={"column"}>
            <Input
              {...register("phone_number", {
                pattern: {
                  value: /^\+?[1-9]\d{1,14}$/,
                  message: "Invalid phone number format.",
                },
              })}
              bg={COLORS.white}
              height={9}
            />
            <FormErrorMessage>{errors.mobile?.message}</FormErrorMessage>
          </Flex>
        </FormControl>
      </Flex>
    </form>
  )
}

export default UpdateUserForm
