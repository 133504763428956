import FlightsTable from "./components/FlightsTable"

const AmadeusFlightDetail = ({
  data,
  status,
  isFetching,
  isError,
  error,
  originAirportsQuery,
}) => {
  return (
    <FlightsTable
      data={data}
      status={status}
      isFetching={isFetching}
      isError={isError}
      error={error}
      originAirportsQuery={originAirportsQuery}
    />
  )
}

export default AmadeusFlightDetail
