import React, { useState } from "react"
import ChangePasswordModal from "./ChangePasswordModal"
import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"

const UpdateUserPassword = () => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [successText, setSuccessText] = useState(null)

  const onPasswordChangeSuccess = () => {
    setSuccessText(t("validation.passwordSuccessText"))
  }

  return (
    <>
      <ChangePasswordModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onPasswordChangeSuccess={onPasswordChangeSuccess}
      />

      <Flex gap={"6px"} flexDirection={"column"}>
        <Text
          fontSize={"xs"}
          fontWeight={"semibold"}
          whiteSpace={"nowrap"}
          m={0}
        >
          {t("login.password")}:
        </Text>

        <Button
          width={"100%"}
          fontSize={"14px"}
          fontWeight={700}
          color={COLORS.red}
          textTransform={"uppercase"}
          onClick={onOpen}
          height={9}
        >
          {t("settings.resetPassword")}
        </Button>
        {successText && (
          <Text color={COLORS.green} fontWeight={600} fontSize={"12px"}>
            {successText}
          </Text>
        )}
      </Flex>
    </>
  )
}

export default UpdateUserPassword
