import {
  Flex,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { differenceInDays, parseISO } from "date-fns"

import starMedalIcon from "../../../../img/star_medal.svg"
import twentyFourHoursIcon from "../../../../img/24_hours.svg"

const PopoverBottom = ({ details }) => {
  const { t } = useTranslation()

  const dayDifference = differenceInDays(
    parseISO(details[0].departure_date),
    new Date()
  )

  return (
    <Flex
      flexDirection={"column"}
      width={"100%"}
      padding={"60px 60px 72px"}
      gap={"30px"}
    >
      <Text fontWeight={600} fontSize={"20px"} lineHeight={"30px"}>
        <Trans
          i18nKey={"likelihood.popover.codeShare.title"}
          values={{ day: dayDifference }}
          components={{ bold: <strong /> }}
        />
      </Text>

      <Flex gap={4}>
        <Flex gap={4} alignItems={"flex-start"}>
          <Image src={starMedalIcon} width={"36px"} alt={"Star medal"} />

          <Flex gap={5} flexDirection={"column"} width={"100%"}>
            <Flex gap={4} width={"100%"} alignItems={"center"}>
              <Text fontSize={"24px"} fontWeight={700} lineHeight={"36px"}>
                Upgrade strategies before departure:
              </Text>
            </Flex>

            <UnorderedList
              display={"flex"}
              flexDirection={"column"}
              gap={"22px"}
              fontSize={"20px"}
              fontWeight={600}
            >
              <ListItem>
                Look at the operating airline fare classes to determine unsold
                seats
              </ListItem>
              <ListItem>
                Consider buying a flexible ticket in the below class& request
                upgrade
              </ListItem>
              <ListItem>
                Bid for upgrade if this is offered by the operating airline
              </ListItem>
              <ListItem>
                If you have status & points with the operating airline, ask that
                these seats are designated as reward seats
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={4} alignItems={"flex-start"}>
        <Image src={twentyFourHoursIcon} width={"36px"} alt={"24 hours"} />

        <Flex gap={3} flexDirection={"column"} width={"100%"}>
          <Flex gap={5} flexDirection={"column"}>
            <Text fontSize={"24px"} fontWeight={700} lineHeight={"36px"}>
              Within 24 hrs of the flight:
            </Text>

            <Text fontSize={"20px"} fontWeight={600} marginBottom={3}>
              The remaining seats may be allocated by the operating airline as
              follows:
            </Text>
          </Flex>

          <UnorderedList
            display={"flex"}
            flexDirection={"column"}
            gap={5}
            fontSize={"20px"}
            fontWeight={600}
            marginLeft={8}
            lineHeight={"28px"}
          >
            <ListItem>Customers who have submitted the highest bids</ListItem>
            <ListItem>
              Upgrade requests based on c;lass of ticket currently held
            </ListItem>
            <ListItem>Airline loyalty status</ListItem>
            <ListItem>Operational reasons</ListItem>
          </UnorderedList>
        </Flex>
      </Flex>

      <Text fontSize={"20px"} fontWeight={600} lineHeight={"30px"}>
        {t("likelihood.popover.codeShare.bottomText")}{" "}
        <Link href="#" textDecoration={"underline"} fontWeight={700}>
          click here
        </Link>
        .
      </Text>
    </Flex>
  )
}

export default PopoverBottom
