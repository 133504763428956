import { Box, Flex, Input } from "@chakra-ui/react"
import React, { forwardRef, useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { CustomCalendarContainer } from "./CustomCalendarContainer"

const DatePickerInput = forwardRef((props, ref) => {
  const { value, onClick, handleTogglePicker } = props

  return (
    <Flex onClick={onClick} ref={ref} position="relative">
      <Input
        inputMode="none"
        sx={{ caretColor: "transparent" }}
        value={value}
        onChange={() => {}}
        onClick={handleTogglePicker}
        minHeight={10}
        borderRadius={12}
        _focus={{ border: "1px solid black", boxShadow: "none" }}
        border="1px solid black"
        borderColor="gray.200"
        py={1}
        px={3}
        fontSize="12px"
      />
      <ChevronDownIcon
        boxSize={4}
        position="absolute"
        right="3"
        height="100%"
      />
    </Flex>
  )
})

const AlertDatePicker = ({
  date,
  toDate,
  startDate,
  minDate,
  handleDatePickerChange,
  ...props
}) => {
  const wrapperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useOutsideAlerter(wrapperRef, () => setIsOpen(false))

  function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
      // Function to call on click outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick()
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, onOutsideClick])
  }

  return (
    <Box ref={wrapperRef}>
      <DatePicker
        open={isOpen}
        selected={date}
        startDate={startDate}
        endDate={toDate}
        minDate={minDate}
        dateFormat="EEE, dd MMMM"
        onChange={(date) => {
          handleDatePickerChange(date)
          setIsOpen(false)
        }}
        customInput={
          <DatePickerInput
            handleTogglePicker={() => {
              setIsOpen(!isOpen)
            }}
          />
        }
        calendarContainer={CustomCalendarContainer}
        {...props}
      />
    </Box>
  )
}

export default AlertDatePicker
