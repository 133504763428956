export const DepartAirlaneSVG = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.08333 15.8333H17.9167V17.5H2.08333V15.8333ZM18.3917 8.03333C18.2167 7.36667 17.525 6.96667 16.8583 7.15L12.4333 8.33333L6.66666 2.975L5.07499 3.4L8.52499 9.375L4.38332 10.4833L2.74166 9.2L1.53333 9.525L3.04999 12.1583L3.69166 13.2667L5.02499 12.9167L9.44999 11.725L13.075 10.7583L17.5 9.58333C18.175 9.38333 18.5667 8.7 18.3917 8.03333Z"
      fill={fill}
    />
  </svg>
)

export const ReturnAirlaneSVG = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.08333 15.8333H17.9167V17.5H2.08333V15.8333ZM8.06666 11.0583L11.6917 12.025L16.1167 13.2083C16.7833 13.3833 17.4667 12.9917 17.65 12.325C17.825 11.6667 17.4333 10.975 16.7667 10.7917L12.3417 9.60833L10.0417 2.08333L8.43333 1.66666V8.56666L4.29166 7.45833L3.51666 5.525L2.30833 5.2V9.50833L3.64166 9.86666L8.06666 11.0583Z"
      fill={fill}
    />
  </svg>
)
