import { forwardRef } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { format, isFuture, isSameDay, isToday, addMonths } from "date-fns"
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import {
  COLORS,
  SUBSCRIPTION,
  flightClassesColors,
} from "../../../constants/constants"
import selectArrowDown from "../../../img/select_arrow_down.svg"
import { CalendarContainer } from "react-datepicker"

const { MONTHLY_LITE } = SUBSCRIPTION

const buildGradientString = (availabilities) => {
  const uniqueAvailabilities = Array.from(
    new Set(
      availabilities
        .join(", ")
        .split(", ")
        .map((item) => item.trim())
    )
  )

  const chunks = [
    "transparent 0% 2%",
    uniqueAvailabilities.includes("Economy")
      ? `${flightClassesColors.Economy} 2% 23%`
      : "transparent 2% 23%",
    "transparent 23% 27%",
    uniqueAvailabilities.includes("PremiumEconomy")
      ? `${flightClassesColors["PremiumEconomy"]} 27% 48%`
      : "transparent 27% 48%",
    "transparent 48% 52%",
    uniqueAvailabilities.includes("Business")
      ? `${flightClassesColors["Business"]} 52% 73%`
      : "transparent 52% 73%",
    "transparent 73% 77%",
    uniqueAvailabilities.includes("First")
      ? `${flightClassesColors["First"]} 77% 98%`
      : "transparent 77% 98%",
    "transparent 98% 100%",
  ]
  const result = `conic-gradient(${chunks.join(", ")})`
  return result
}

export const DatePickerInput = forwardRef((props, ref) => {
  const { value, onClick, handleTogglePicker } = props

  let newValue = value

  if (value.endsWith(" - ")) {
    newValue = value.replace(" - ", "")
  }

  return (
    <Flex onClick={onClick} ref={ref} position="relative" fontWeight={600}>
      <Input
        sx={{ caretColor: "transparent" }}
        inputMode="none"
        value={newValue}
        onChange={() => {}}
        onClick={handleTogglePicker}
        minHeight={10}
        borderRadius={12}
        _focus={{ border: "1px solid black", boxShadow: "none" }}
        border="1px solid black"
        borderColor="gray.200"
        py={1}
        pl={3}
        pr={0}
        fontWeight={600}
      />
      <Image
        src={selectArrowDown}
        boxSize={3}
        position="absolute"
        right="2"
        height="100%"
      />
    </Flex>
  )
})

export const RenderCustomHeader = ({
  isOneWay,
  twoMonthsLater,
  oneYearLater,
  isFreePlan,
  pricingPlans,
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  customHeaderCount,
  user,
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const monthPlan = pricingPlans.find(
    ({ nickname }) => nickname === MONTHLY_LITE
  )

  const handleOnNextMonthClick = () => {
    const currentMonth = addMonths(date, isOneWay ? 0 : 1).getMonth()
    const currentYear = addMonths(date, isOneWay ? 0 : 1).getFullYear()

    if (isFreePlan && currentMonth === twoMonthsLater.getMonth()) {
      onOpen()
      return
    }

    if (
      currentMonth === oneYearLater.getMonth() &&
      currentYear === oneYearLater.getFullYear()
    ) {
      return
    }

    increaseMonth()
  }

  return (
    <Flex justify="center" borderRadius={12}>
      {!prevMonthButtonDisabled && !customHeaderCount ? (
        <button
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={decreaseMonth}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            {t("filterPanel.previousMonth")}
          </span>
        </button>
      ) : null}

      <div
        className="react-datepicker__current-month"
        style={{ userSelect: "none" }}
      >
        {!customHeaderCount
          ? format(date, "MMMM yyyy")
          : format(addMonths(date, 1), "MMMM yyyy")}
      </div>

      <Popover isOpen={isOpen} onClose={onClose} closeOnBlur={false}>
        <PopoverTrigger>
          <div>
            {(!(user && nextMonthButtonDisabled) &&
              isOneWay &&
              !customHeaderCount) ||
            (!isOneWay &&
              customHeaderCount &&
              !(
                user &&
                addMonths(date, isOneWay ? 0 : 1).getMonth() ===
                  oneYearLater.getMonth() &&
                addMonths(date, isOneWay ? 0 : 1).getFullYear() ===
                  oneYearLater.getFullYear()
              )) ? (
              <button
                className="react-datepicker__navigation react-datepicker__navigation--next"
                onClick={handleOnNextMonthClick}
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                  {t("filterPanel.nextMonth")}
                </span>
              </button>
            ) : null}
          </div>
        </PopoverTrigger>
        <PopoverContent py={5}>
          <PopoverCloseButton />
          <PopoverBody>
            <Heading as="h3" fontSize="md" mb={3} fontWeight="bold">
              {t("filterPanel.60daysAlert.header")}
            </Heading>
            <Text fontWeight="semibold">
              {t("filterPanel.60daysAlert.text")}
            </Text>
            <Divider my={2} />
            <Text fontWeight="semibold">
              {t("filterPanel.60daysAlert.text2")}
            </Text>
            <Flex alignItems="baseline" justifyContent="center" gap={2} mb={2}>
              <Text fontSize="xs">
                {t("filterPanel.60daysAlert.textBeforePrice")}
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                ${monthPlan?.amount}
              </Text>
              <Text fontSize="xs">
                {t("filterPanel.60daysAlert.textAfterPrice")}
              </Text>
            </Flex>
            <Button
              as={RouterLink}
              to={`/register?pricing_plan=${MONTHLY_LITE}`}
              w="100%"
              backgroundColor={COLORS.red}
              color="white"
            >
              {t("login.signUp")}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}

export const RenderDayContents = ({ flightDatesQuery, day, date }) => {
  const dayWithAvailability = flightDatesQuery.data.find((allowedDate) =>
    isSameDay(date, new Date(allowedDate.date))
  )

  const isAvailableDay = isFuture(date) || isToday(date)

  return (
    <Flex
      className={isAvailableDay ? "color-wheel" : ""}
      height="45px"
      position="relative"
      align="center"
      justify="center"
      background={
        dayWithAvailability && isFuture(date)
          ? buildGradientString(dayWithAvailability.availabilities)
          : "none"
      }
    >
      <Text position="absolute" zIndex={1}>
        {day}
      </Text>
    </Flex>
  )
}

const Circle = ({ color }) => {
  return (
    <Box
      borderRadius="50%"
      width="6px"
      height="6px"
      background={color}
      margin="0 auto"
    ></Box>
  )
}

export const CustomCalendarContainer = ({
  handleClosePicker,
  className,
  children,
}) => {
  return (
    <div className="datepicker-wrapper">
      <CalendarContainer className={className}>{children}</CalendarContainer>
      <Flex
        borderTop="1px solid rgba(0, 0, 0, 0.12)"
        justifyContent="space-around"
        py="24px"
        mx={3}
      >
        <Box>
          <Circle color={flightClassesColors.Economy} />
          <Text mt={1} color={COLORS.black} fontWeight="semibold" fontSize="xs">
            Economy
          </Text>
        </Box>
        <Box>
          <Circle color={flightClassesColors.PremiumEconomy} />
          <Text mt={1} color={COLORS.black} fontWeight="semibold" fontSize="xs">
            Premium Economy
          </Text>
        </Box>
        <Box>
          <Circle color={flightClassesColors.Business} />
          <Text mt={1} color={COLORS.black} fontWeight="semibold" fontSize="xs">
            Business Class
          </Text>
        </Box>
        <Box>
          <Circle color={flightClassesColors.First} />
          <Text mt={1} color={COLORS.black} fontWeight="semibold" fontSize="xs">
            First Class
          </Text>
        </Box>
      </Flex>
      <Flex
        justifyContent={"flex-end"}
        backgroundColor={COLORS.white}
        filter={"drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.05))"}
        display={{ base: "flex", md: "none" }}
        borderBottomRadius={{ base: 0, md: 12 }}
      >
        <Button
          backgroundColor={COLORS.grey}
          color={COLORS.red}
          borderRadius={8}
          my={5}
          mx={3}
          fontWeight={"700"}
          textTransform={"uppercase"}
          onClick={handleClosePicker}
        >
          Cancel
        </Button>
      </Flex>
    </div>
  )
}
