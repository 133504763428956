import { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Show,
  Flex,
  useOutsideClick,
} from "@chakra-ui/react"

import { ITEMS_PER_PAGE } from "../../../constants/constants"

import Pagination from "../../Pagination"
import usePagination from "../../../hooks/usePagination"
import FlightsNotFound from "../../FlightsNotFound"
import FlightRow from "../components/FlightRow"
import LoadingAnimation from "../../LoadingAnimation"

const FlightsTable = ({ flights, isFetching, user }) => {
  const [expandedFlight, setExpandedFlight] = useState(null)
  const { page, onPrev, onNext, onCurrent } = usePagination()
  const ref = useRef()
  const { t } = useTranslation()

  useOutsideClick({
    ref,
    handler: () => setExpandedFlight(null),
  })

  const totalPages = Math.ceil((flights?.count || 0) / ITEMS_PER_PAGE)
  const handleOnNext = () => onNext(totalPages)

  if (isFetching) {
    return <LoadingAnimation />
  }

  return (
    <Flex
      flexDirection={"column"}
      bg={{ base: "initial", lg: "white" }}
      borderRadius={{ base: 0, lg: 12 }}
      boxShadow="0px 2px 8px 0px rgba(20, 23, 37, 0.08)"
    >
      {!flights.results?.length ? (
        <FlightsNotFound text={"table.empty"} />
      ) : (
        <Table
          width="100%"
          ref={ref}
          bg={{ base: "initial", lg: "white" }}
          variant="rounded"
        >
          <Thead>
            <Tr
              fontSize={[10, 12]}
              sx={{ "& > th": { borderBottom: "1px solid #E6E6E6" } }}
            >
              <Show above="lg">
                <Th textTransform="none" p={4}></Th>
              </Show>
              <Th
                textTransform="none"
                px={2}
                py={4}
                w={{ base: "35%", lg: "15%" }}
              >
                {t("table.itinerary")}
              </Th>
              <Show below="lg">
                <Th textTransform="none" px={2} py={4}>
                  {t("table.pointsRequired")}
                </Th>
              </Show>
              <Show above="lg">
                <Th textTransform="none" px={2} py={4}>
                  {t("table.stops")}
                </Th>
                <Th textTransform="none" px={2} py={4} w={{ lg: "10%" }}>
                  {t("table.economy")}
                </Th>
                <Th textTransform="none" px={2} py={4} w={{ lg: "10%" }}>
                  {t("table.premiumEconomy")}
                </Th>
                <Th textTransform="none" px={2} py={4} w={{ lg: "10%" }}>
                  {t("table.business")}
                </Th>
                <Th textTransform="none" px={2} py={4} w={{ lg: "10%" }}>
                  {t("table.first")}
                </Th>
              </Show>
              <Th textTransform="none" textAlign="center" px={2} py={4}>
                {t("table.bookHeader")}
              </Th>
              <Show above="lg">
                <Th textTransform="none" textAlign="center" px={2} py={4}>
                  {t("table.earnPoints")}
                </Th>
              </Show>
              <Th textTransform="none" textAlign="center" px={2} py={4}>
                {t("table.alert")}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {flights?.results.map((flight) => (
              <FlightRow
                key={flight.id}
                flight={flight}
                expandedFlight={expandedFlight}
                setExpandedFlight={setExpandedFlight}
                user={user}
              />
            ))}
          </Tbody>
        </Table>
      )}

      <Pagination
        page={page}
        onCurrent={onCurrent}
        onNext={handleOnNext}
        onPrev={onPrev}
        totalPages={totalPages}
      />
    </Flex>
  )
}

export default FlightsTable
