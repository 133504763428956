import { Flex, Stack } from "@chakra-ui/react"
import React from "react"
import Menu from "./Menu"
import { COLORS } from "../constants/constants"

const Layout = ({ isOpen, handleToggleMenu, children, ...props }) => (
  <Stack
    position="relative"
    direction={{ base: "column", lg: "row" }}
    minHeight="100vh"
    spacing={0}
    backgroundColor={COLORS.grey}
    {...props}
  >
    <Menu isOpen={isOpen} handleToggleMenu={handleToggleMenu} />
    <Flex
      w={{ base: "100%", lg: "calc(100% - 260px)" }}
      mx="0 auto"
      px={{ base: 0, lg: isOpen ? "30px" : "74px" }}
      py={{ base: 6, lg: "30px" }}
      flexGrow={1}
      flexDirection={"column"}
    >
      {children}
    </Flex>
  </Stack>
)

export default Layout
