import {
  Button,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useRef } from "react"

const ChartFilters = ({
  chartFilters,
  sources,
  aircraftDetails,
  handleChartFilterChange,
}) => {
  const contentRef = useRef(null)
  const { onOpen, onClose, isOpen } = useDisclosure()

  const handleOnFocus = useCallback(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.focus()
    }
  }, [contentRef, isOpen])

  useEffect(() => {
    handleOnFocus()
    return () => {
      handleOnFocus()
    }
  }, [handleOnFocus, sources, aircraftDetails])

  return (
    <Flex gap={4} textAlign={"left"}>
      <Popover
        _focus={{ boxShadow: "none" }}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button>+ Add Filter</Button>
        </PopoverTrigger>
        <PopoverContent _focus={{ boxShadow: "none" }} ref={contentRef}>
          <Tabs mx={2}>
            <TabList>
              <Tab padding={3}>Program</Tab>
              <Tab padding={3}>Airlines</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <List>
                  <RadioGroup
                    value={chartFilters.source}
                    onChange={(e) => {
                      handleChartFilterChange({ name: "source", value: e })
                    }}
                    onClick={(e) => {
                      if (e.target.value === chartFilters.source) {
                        handleChartFilterChange({
                          name: "source",
                          value: null,
                        })
                      }
                    }}
                  >
                    {sources.map((source, index) => (
                      <ListItem key={index}>
                        <Radio value={source}>{source}</Radio>
                      </ListItem>
                    ))}
                  </RadioGroup>
                </List>
              </TabPanel>
              <TabPanel>
                <List>
                  <RadioGroup
                    value={chartFilters.aircraft}
                    onChange={(e) => {
                      handleChartFilterChange({ name: "aircraft", value: e })
                    }}
                    onClick={(e) => {
                      if (e.target.value === chartFilters.aircraft) {
                        handleChartFilterChange({
                          name: "aircraft",
                          value: null,
                        })
                      }
                    }}
                  >
                    {aircraftDetails.map((aircraft, index) => (
                      <ListItem key={index}>
                        <Radio value={aircraft}>{aircraft}</Radio>
                      </ListItem>
                    ))}
                  </RadioGroup>
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}

export default ChartFilters
