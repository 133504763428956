import { forwardRef } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { format, addMonths } from "date-fns"
import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Text,
} from "@chakra-ui/react"
import selectArrowDown from "../../../img/select_arrow_down.svg"
import { CalendarContainer } from "react-datepicker"
import { COLORS, SUBSCRIPTION } from "../../../constants/constants"

const { MONTHLY_MAX } = SUBSCRIPTION

export const DatePickerInput = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const {
    value,
    onClick,
    handleTogglePicker,
    showPopover,
    onClosePopover,
    pricingPlans,
  } = props
  const monthPlan = pricingPlans.find(
    ({ nickname }) => nickname.toLowerCase() === MONTHLY_MAX
  )
  let newValue = value

  if (value.endsWith(" - ")) {
    newValue = value.replace(" - ", "")
  }

  return (
    <div>
      <Flex onClick={onClick} ref={ref} position="relative">
        <Input
          sx={{ caretColor: "transparent" }}
          inputMode="none"
          value={newValue}
          onChange={() => {}}
          onClick={handleTogglePicker}
          minHeight={10}
          borderRadius={12}
          _focus={{ border: "1px solid black", boxShadow: "none" }}
          border="1px solid black"
          borderColor="gray.200"
          py={1}
          pl={3}
          pr={0}
        />
        <Image
          src={selectArrowDown}
          boxSize={3}
          position="absolute"
          right="2"
          height="100%"
        />
      </Flex>

      <Popover
        isOpen={showPopover}
        onClose={onClosePopover}
        closeOnBlur={false}
      >
        <PopoverContent py={5} top={"40px"}>
          <PopoverCloseButton />
          <PopoverBody>
            <Heading as="h3" fontSize="md" mb={3} fontWeight="bold">
              {t("filterPanel.60daysAlert.header")}
            </Heading>
            <Text fontWeight="semibold">
              {t("filterPanel.60daysAlert.text")}
            </Text>
            <Divider my={2} />
            <Text fontWeight="semibold">
              {t("filterPanel.60daysAlert.text2")}
            </Text>
            <Flex alignItems="baseline" justifyContent="center" gap={2} mb={2}>
              <Text fontSize="xs">
                {t("filterPanel.60daysAlert.textBeforePrice")}
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                ${monthPlan?.amount}
              </Text>
              <Text fontSize="xs">
                {t("filterPanel.60daysAlert.textAfterPrice")}
              </Text>
            </Flex>
            <Button
              as={RouterLink}
              to={`/register?pricing_plan=${MONTHLY_MAX}`}
              w="100%"
              backgroundColor={COLORS.red}
              color="white"
            >
              {t("login.signUp")}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  )
})

export const RenderCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  customHeaderCount,
}) => {
  const { t } = useTranslation()

  return (
    <Flex justify="center">
      {!prevMonthButtonDisabled && !customHeaderCount ? (
        <button
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={decreaseMonth}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            {t("filterPanel.previousMonth")}
          </span>
        </button>
      ) : null}

      <div className="react-datepicker__current-month">
        {!customHeaderCount
          ? format(date, "MMMM yyyy")
          : format(addMonths(date, 1), "MMMM yyyy")}
      </div>

      {!nextMonthButtonDisabled ? (
        <div>
          <button
            className="react-datepicker__navigation react-datepicker__navigation--next"
            onClick={increaseMonth}
          >
            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
              {t("filterPanel.nextMonth")}
            </span>
          </button>
        </div>
      ) : null}
    </Flex>
  )
}

export const RenderDayContents = ({ day }) => {
  return (
    <Flex
      className={"color-wheel"}
      height="45px"
      position="relative"
      align="center"
      justify="center"
    >
      <Text position="absolute" zIndex={1}>
        {day}
      </Text>
    </Flex>
  )
}

export const CustomCalendarContainer = ({
  handleClosePicker,
  className,
  children,
}) => {
  return (
    <div className="datepicker-wrapper">
      <CalendarContainer className={className}>{children}</CalendarContainer>
      <Flex
        display={{ base: "flex", md: "none" }}
        justifyContent={"flex-end"}
        backgroundColor={COLORS.white}
        filter={"drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.05))"}
      >
        <Button
          backgroundColor={COLORS.grey}
          color={COLORS.red}
          borderRadius={8}
          my={5}
          mx={3}
          fontWeight={700}
          textTransform={"uppercase"}
          onClick={handleClosePicker}
        >
          Cancel
        </Button>
      </Flex>
    </div>
  )
}
