import React from "react"
import { Flex } from "@chakra-ui/react"

import PopoverTop from "./PopoverTop"
import PopoverBottom from "./PopoverBottom"

const Popover = ({
  planeImage,
  flight,
  planeAltText,
  details,
  aircraftCode,
  flightDetail,
  popoverFlightsTexts,
  summaryPoints,
}) => {
  let regex1 = /(.*?:)\s(.*)/
  let regex2 = /(.*?)\s(\d+)/
  let aircraft = { name: null, code: null }

  if (aircraftCode.includes("Airline code:")) {
    let [, name, code] = aircraftCode.match(regex1)

    aircraft.name = name
    aircraft.code = code
  } else {
    let [, name, code] = aircraftCode.match(regex2)

    aircraft.name = name
    aircraft.code = code
  }

  const handleClick = (e) => {
    e.stopPropagation()
  }

  return (
    <Flex
      width={"100vw"}
      maxWidth={"866px"}
      flexDirection={"column"}
      borderRadius={"50px"}
      overflow={"hidden"}
      backgroundColor={"white"}
      onClick={handleClick}
    >
      <PopoverTop
        percentage={flight.fullness_percentage}
        details={details}
        planeImage={planeImage}
        planeAltText={planeAltText}
        aircraft={aircraft}
        flightDetail={flightDetail}
        popoverFlightsTexts={popoverFlightsTexts}
      />
      <PopoverBottom
        details={details}
        aircraftCode={aircraftCode}
        code={aircraft.code}
        summaryPoints={summaryPoints}
      />
    </Flex>
  )
}

export default Popover
