import { Button, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { COLORS } from "../../../constants/constants"
import UpdateUserForm from "./UpdateUserForm"
import UpdateUserEmail from "./UpdateUserEmail"
import UpdateUserPassword from "./UpdateUserPassword"

const EditUserDetails = ({ user, handleCloseEdit }) => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection={"column"}
      width={"100%"}
      maxWidth={{ base: "100%", md: "314px" }}
      gap={"24px"}
    >
      <UpdateUserForm user={user} handleCloseEdit={handleCloseEdit} />
      <UpdateUserEmail user={user} />
      <UpdateUserPassword />

      <Flex
        gap={5}
        sx={{
          "& > button > p": {
            textTransform: "uppercase",
            fontSize: "14px",
            fontWeight: 700,
          },
        }}
      >
        <Button
          type={"submit"}
          form={"user-settings-form"}
          width={"100%"}
          backgroundColor={COLORS.red}
          color={COLORS.white}
        >
          <Text>{t("settings.save")}</Text>
        </Button>
        <Button
          type={"button"}
          width={"100%"}
          color={COLORS.red}
          onClick={handleCloseEdit}
        >
          <Text>{t("settings.cancel")}</Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default EditUserDetails
