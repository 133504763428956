import { Flex, Heading, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const leftFAQsSections = [
  {
    id: 1,
    title: "amadeus.faqs.question1",
    description: "amadeus.faqs.answer1",
  },
  {
    id: 2,
    title: "amadeus.faqs.question2",
    description: "amadeus.faqs.answer2",
  },
]

const rightFAQsSections = [
  {
    id: 1,
    title: "amadeus.faqs.question3",
    description: "amadeus.faqs.answer3",
  },
  {
    id: 2,
    title: "amadeus.faqs.question4",
    description: "amadeus.faqs.answer4",
  },
]

const FAQsBetweenFlights = ({ flightsTexts }) => {
  const { t } = useTranslation()
  const { from, to } = flightsTexts

  return (
    <Flex
      flexDirection={"column"}
      gap={"30px"}
      padding={"30px"}
      backgroundColor={"white"}
      borderRadius={"12px"}
      textAlign={"left"}
    >
      <Heading as={"h2"} fontSize="24px" textAlign={"left"}>
        {t("amadeus.faqs.title", { from: from.name, to: to.name })}
      </Heading>

      <Flex gap={"30px"}>
        <Flex flexDirection={"column"} gap={"24px"}>
          {leftFAQsSections.map(({ id, title, description }) => (
            <Flex key={id} flexDirection={"column"} gap={"8px"}>
              <Heading as={"h4"} fontSize={"16px"}>
                {t(title, { from: from.name, to: to.name })}
              </Heading>
              <Text fontSize={"14px"}>
                {t(description, { from: from.name, to: to.name })}
              </Text>
            </Flex>
          ))}
        </Flex>

        <Flex flexDirection={"column"} gap={"24px"}>
          {rightFAQsSections.map(({ id, title, description }) => (
            <Flex key={id} flexDirection={"column"} gap={"8px"}>
              <Heading as={"h4"} fontSize={"16px"}>
                {t(title, { from: from.name, to: to.name })}
              </Heading>
              <Text fontSize={"14px"}>
                {t(description, { from: from.name, to: to.name })}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FAQsBetweenFlights
