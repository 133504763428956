import { Flex, Text } from "@chakra-ui/react"
import React from "react"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"

const AdditionalUserInfo = ({ user }) => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection={"column"}
      flex={{ base: "0 1 100%", md: "0 1 40%", xl: "0 1 33%" }}
      textAlign={"left"}
      gap={4}
      maxWidth={{ base: "100%", md: "345px" }}
      fontWeight={600}
    >
      <Flex gap={"42px"}>
        <Flex flexDirection={"column"} gap={"2px"}>
          <Text fontSize={"12px"}>{t("login.currentPlan")}</Text>
          <Text color={COLORS.red}>
            {t(`pricing.${user.subscription.toLowerCase()}.header`)}
          </Text>
        </Flex>

        <Flex flexDirection={"column"} gap={"2px"}>
          <Text fontSize={"12px"}>{t("login.activeAlerts")}</Text>
          <Text color={COLORS.red}>{user.active_alerts}</Text>
        </Flex>
      </Flex>

      <Flex flexDirection={"column"} gap={"2px"}>
        <Text fontSize={"12px"}>{t("login.queriesText")}</Text>
        <Text color={COLORS.red}>{user.remaining_requests}</Text>
      </Flex>
    </Flex>
  )
}

export default AdditionalUserInfo
