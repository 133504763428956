import FlightsTable from "./FlightsTable/FlightsTable"

const AustralianFlightDetail = ({
  isFetching,
  flights,
  user,
  count,
  orderBy,
  setOrderBy,
  descending,
  setDescending,
  destinationRegion,
}) => {
  return (
    <FlightsTable
      isFetching={isFetching}
      flights={flights}
      user={user}
      count={count}
      orderBy={orderBy}
      descending={descending}
      setOrderBy={setOrderBy}
      setDescending={setDescending}
      destinationRegion={destinationRegion}
    />
  )
}

export default AustralianFlightDetail
