import { Flex } from "@chakra-ui/react"
import PricingPlans from "../components/PricingPlans"
import Footer from "../components/Footer"
import { COLORS } from "../constants/constants"

export const Pricing = () => {
  return (
    <Flex
      bg={COLORS.grey}
      flexGrow={1}
      minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <PricingPlans />

      <Footer />
    </Flex>
  )
}

export default Pricing
