import { Box, Text, Flex, Image } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

import instagramImage from "../img/instagram.svg"
import facebookImage from "../img/facebook.svg"

export default function Footer() {
  const { t } = useTranslation()

  return (
    <Box px={{ base: 4, lg: 0 }}>
      <Text fontSize={{ base: "10px", lg: "small" }} mb={3} color="#6A6E85">
        {t("allRewardFooterLine1")}
      </Text>
      <Text fontSize={{ base: "10px", lg: "small" }} mb={3} color="#6A6E85">
        {t("allRewardFooterLine2")}
      </Text>
      <Text fontSize={{ base: "10px", lg: "small" }} color="#6A6E85">
        {t("allRewardFooterLine3")}
      </Text>
      <Flex py={5} justify="center">
        <a target="_blank" href={t("links.instagramLink")} rel="noreferrer">
          <Image
            src={instagramImage}
            mr={6}
            alt={"discover_reward_flight_inspiration_on_instagram"}
          />
        </a>
        <a target="_blank" href={t("links.facebookLink")} rel="noreferrer">
          <Image
            src={facebookImage}
            alt={"follow_gyoza_flights_on_facebook_for_reward_flight_alerts"}
          />
        </a>
      </Flex>
      <Flex
        color="#6A6E85"
        fontSize={{ base: "10px", lg: "small" }}
        gap={3}
        justify="center"
      >
        <RouterLink to="/privacy-policy">{t("privacyPolicy")}</RouterLink>
        <RouterLink to="/terms-and-conditions">
          {t("termsAndConditions")}
        </RouterLink>
        <Text>{t("allRewardFooterSummary")}</Text>
      </Flex>
    </Box>
  )
}
