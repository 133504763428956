import { Box, Flex } from "@chakra-ui/react"
import React from "react"

import {} from "recharts"
import ChartFilters from "./ChartFilters"
import ChartView from "./ChartView"
import { CHART_BAR_DATA } from "../../../constants/constants"

const RemainingSeatsChart = ({
  chartData,
  isFetchingChartData,
  chartFilters,
  screenWidth,
  handleChartFilterChange,
  parentWidth,
  setParentWidth,
}) => {
  return (
    <>
      <Box
        display={{ base: "none", md: "flex" }}
        position={"relative"}
        gap={4}
        height={"425px"}
        flexDirection={"column"}
        alignItems={"center"}
        padding={"25px 40px 45px"}
        boxShadow={"#CECECE 0px 0px 6px 2px"}
        borderRadius={"5px"}
        margin={"20px 0"}
        width={"100%"}
        backgroundColor={"#fff"}
      >
        <Flex width={"100%"}>
          <Flex
            flex={1}
            justifyContent={"center"}
            alignItems={"center"}
            gap={5}
          >
            {CHART_BAR_DATA.map(({ dataKey, fill, title }) => (
              <div
                key={dataKey}
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div style={{ backgroundColor: fill, width: 10, height: 10 }} />
                <p>{title}</p>
              </div>
            ))}
          </Flex>
          <ChartFilters
            chartFilters={chartFilters}
            sources={chartData.all_sources}
            aircraftDetails={chartData.all_aircraft_details}
            handleChartFilterChange={handleChartFilterChange}
          />
        </Flex>

        <ChartView
          parentWidth={parentWidth}
          setParentWidth={setParentWidth}
          chartData={chartData}
          isFetchingChartData={isFetchingChartData}
          screenWidth={screenWidth}
        />
      </Box>
    </>
  )
}

export default RemainingSeatsChart
