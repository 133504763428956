import { Flex, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"

const UserDetails = ({ user }) => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection={"column"}
      gap={2}
      fontSize={"14px"}
      sx={{
        "& > div > p:first-of-type": {
          width: "140px",
          fontWeight: 600,
        },
        "& > div > p:last-of-type": {
          fontWeight: 500,
        },
      }}
    >
      <Flex>
        <Text>{t("register.firstName")}</Text>
        <Text>{user.first_name}</Text>
      </Flex>

      <Flex>
        <Text>{t("register.lastName")}</Text>
        <Text>{user.last_name}</Text>
      </Flex>

      <Flex>
        <Text>{t("login.mobile")}</Text>
        <Text>{user.phone_number}</Text>
      </Flex>

      <Flex>
        <Text>{t("login.email")}</Text>
        <Text>{user.email}</Text>
      </Flex>

      <Flex>
        <Text>{t("login.password")}</Text>
        <Text>**********</Text>
      </Flex>
    </Flex>
  )
}

export default UserDetails
