import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"
import stripeLogo from "../../../img/stripe-logo.svg"
import { useMutation } from "@tanstack/react-query"
import { fetchBillingRedirect } from "../../../services/api"

const Billing = ({ user }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync: fetchStripeRedirect } = useMutation({
    mutationFn: fetchBillingRedirect,
    onSuccess: (data) => {
      window.location.href = data.redirect_url
    },
    onError: (error) => {
      console.error("Failed to fetch Stripe login URL:", error)
    },
  })

  const handleStripeLoginClick = async () => {
    if (user.id) {
      setIsLoading(true) // Start loading
      try {
        await fetchStripeRedirect({ userId: user.id })
      } catch (error) {
        console.error("Error logging into Stripe:", error)
      } finally {
        setIsLoading(false) // Stop loading
      }
    }
  }

  return (
    <Flex bg={COLORS.grey} flexDirection={"column"} gap={5}>
      <Heading
        as="h1"
        color={COLORS.black}
        textAlign="left"
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("settings.billing.header")}
      </Heading>

      <Flex
        backgroundColor={COLORS.white}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={{ base: "column", sm: "row" }}
        padding={"24px"}
        borderRadius={"12px"}
        textAlign={"left"}
        gap={"20px"}
        boxShadow={"0px 1px 2px rgba(0, 0, 0, 0.1)"}
      >
        <Flex alignItems={"center"} gap={"24px"}>
          <Image src={stripeLogo} />

          <Text fontSize={"14px"} fontWeight={600} maxWidth={"230px"}>
            {t("settings.billing.description")}
          </Text>
        </Flex>

        <Button
          type="button"
          width={{ base: "100%", sm: "max-content" }}
          onClick={handleStripeLoginClick}
          backgroundColor="#DD0000"
          textTransform={"uppercase"}
          padding={"0 20px"}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <Text color="white" fontSize={"14px"} fontWeight={700}>
            {t("settings.loginToStripe")}
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default Billing
