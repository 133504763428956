import React from "react"

const AirplaneIcon = ({ color = "#fff" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2623_7992)">
      <path
        fill={color}
        d="M15.9998 8C15.9998 8.70238 15.4625 9.26786 14.8008 9.2619L10.4007 9.2619L6.4022 15.994L4.80165 16L6.8094 9.26786L2.398 9.26786L1.20465 10.9524L-2.20899e-07 10.9464L0.803102 8.00595L-2.08801e-07 5.05357L1.21031 5.04167L2.41496 6.75L6.79244 6.75L4.80165 7.43788e-07L6.40219 0.00595256L10.4007 6.7381L14.8008 6.73809C15.4456 6.72619 16.0111 7.32143 15.9998 8Z"
      />
    </g>
  </svg>
)

export default AirplaneIcon
