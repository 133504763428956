import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import {
  format,
  differenceInCalendarDays,
  parseISO,
  formatDistanceToNow,
  addDays,
} from "date-fns"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"

import { trackPage } from "../../../services/analytics"

import bellImage from "../../../img/bell.svg"

import {
  Text,
  Tr,
  Td,
  Image,
  Show,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Box,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react"
import {
  AIRPLANE_COLORS,
  COLORS,
  DATE_FORMAT_AUSTRALIA_DEPART,
  flightClassesMapping,
} from "../../../constants/constants"

import ExpandableRow from "../components/ExpandableRow"
import AirplaneIcon from "../../../img/DynamicSVGs"
import flightImages from "../../../flightImages"
import {
  formatTime,
  formatUTCDate,
  renderBookingDetails,
} from "../../../helpers/functions"
import AlertRouteContent from "../../AlertRouteContent"

const numberFormat = new Intl.NumberFormat()

const parseDate = (dateStr) => {
  return parseISO(dateStr)
}

const FlightRow = ({
  flight,
  user,
  expandedFlight,
  setExpandedFlight,
  destinationRegion,
  isMonthlyMaxOrAnnual,
}) => {
  const { t } = useTranslation()
  const placement = useBreakpointValue({ base: "bottom", sm: "left" })

  const canCreateAlerts = user?.remaining_alerts || 0

  const { details } = flight

  let firstAirlineImageAlt = ""
  let secondAirlineImageAlt = ""

  const summaryPoints = flight.class_details.reduce(
    (acc, item) => ({
      ...acc,
      [item.designated_class]: {
        points: item.points_per_adult,
        name: t(`table.${flightClassesMapping[item.designated_class]}`),
        tax_currency: item.tax_currency,
        tax_per_adult: item.tax_per_adult,
        remaining_seats: item.remaining_seats,
        mixed_cabin: item.mixed_cabin,
      },
    }),
    {}
  )

  const highestPoint =
    summaryPoints["First"] ||
    summaryPoints["Business"] ||
    summaryPoints["PremiumEconomy"] ||
    summaryPoints["Economy"]

  // set How to Book? content based on airline source
  const {
    airlineAwardImage,
    bookingContentComponent,
    airlineAwardImageAlt,
    preferredPrograms,
  } = renderBookingDetails(flight, highestPoint, t)

  const departureDate = new Date(details[0].departure_date)
  const arrivalDate = new Date(details[details.length - 1].arrival_date)
  const diffInDays = differenceInCalendarDays(arrivalDate, departureDate)

  const route = {
    origin: flight.origin,
    destination: flight.destination,
    startDate: format(parseISO(flight.flight_start_date), "yyyy-MM-dd"),
    endDate: format(
      addDays(parseISO(flight.flight_start_date), 1),
      "yyyy-MM-dd"
    ),
    flightClasses: Object.keys(summaryPoints),
    preferredPrograms: [preferredPrograms],
    source: [flight.source],
    region: false,
  }

  const isFlightExpanded = expandedFlight === flight

  const lastSeenText = formatDistanceToNow(parseDate(flight.timestamp), {
    addSuffix: true,
  })
  const shouldIncludeAbout =
    !lastSeenText.includes("about") && lastSeenText.includes("ago")

  const planeImage =
    details.length >= 3
      ? flightImages("group_3_plus")
      : flightImages(details[0].aircraft_details.slice(0, 2))

  const secondPlaneImage =
    details.length === 2 &&
    flightImages(details[1]?.aircraft_details.slice(0, 2))

  const checkIfSameAirline = (details) => {
    const regex = /\((.*?)\)/

    // Extract the company names from each string in the array
    const companyNames = details.map((detail) => {
      const match = detail.aircraft_details.match(regex)

      return match ? match[1] : null
    })

    // Check if all extracted company names are the same
    return companyNames.every((name, _, arr) => name === arr[0])
  }

  const hasTwoImages = secondPlaneImage && !checkIfSameAirline(details)

  const defaultImageStyle = (isTwoImages, idx) => ({
    w: "100%",
    maxW: "32px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: isTwoImages
      ? !idx
        ? "translate(calc(-50% + 3.75px), calc(-50% + 1.8px))"
        : "translate(calc(-50% - 3.75px), calc(-50% - 1.8px))"
      : "translate(-50%, -50%)",
  })

  return (
    <Fragment key={flight.id}>
      <Tr
        fontSize={[12, 14]}
        position={isFlightExpanded ? "relative" : "initial"}
        zIndex={100}
        backgroundColor={isFlightExpanded ? COLORS.grey : COLORS.white}
        transform={isFlightExpanded ? "translateZ(1px)" : "none"}
        boxShadow={
          isFlightExpanded
            ? "0px -10px 18px 0px rgba(20, 23, 37, 0.13)"
            : "none"
        }
        fontWeight="semibold"
        onClick={() => {
          setExpandedFlight(expandedFlight === flight ? null : flight)
        }}
        cursor="pointer"
      >
        <Td p={{ base: 3, lg: 4 }} fontWeight={600} fontSize={12}>
          {formatUTCDate(
            flight.details[0].departure_date,
            DATE_FORMAT_AUSTRALIA_DEPART
          )}
        </Td>
        <Td
          p={{ base: 3, lg: 4 }}
          width="50px"
          border={isFlightExpanded ? "none" : ""}
        >
          <Text fontSize={12}>{flight.origin.city}</Text>
          {window.innerWidth < 992 && (
            <Text fontSize={11} color={"#6A6E85"}>
              {formatTime(flight.flight_start_date)}
              {diffInDays > 0 && <Text as="sup"> +{diffInDays}</Text>}
            </Text>
          )}
        </Td>
        <Td
          border={isFlightExpanded ? "none" : ""}
          p={{ base: 3, lg: 4 }}
          width="50px"
        >
          <Text fontSize={12}>{flight.destination.city}</Text>
          {window.innerWidth < 992 && (
            <Text fontSize={11} color={"#6A6E85"}>
              {formatTime(flight.flight_end_date)}
              {diffInDays > 0 && <Text as="sup"> +{diffInDays}</Text>}
            </Text>
          )}
        </Td>
        <Show above="lg">
          <Td p={{ base: 3, lg: 4 }} border={isFlightExpanded ? "none" : ""}>
            <Text>
              {formatTime(flight.flight_start_date)} -{" "}
              {formatTime(flight.flight_end_date)}
              {diffInDays > 0 && <Text as="sup"> +{diffInDays}</Text>}
            </Text>
            <Text fontSize={12}>
              {details
                .map((conn) => conn.aircraft_details.split("(")[0])
                .join(", ")}
            </Text>
          </Td>
        </Show>

        <Show above="lg">
          <Td
            p={{ base: 3, lg: 4 }}
            border={isFlightExpanded ? "none" : ""}
            fontSize={"12px"}
          >
            <Text>{details.length === 1 ? "Direct" : details.length - 1}</Text>
            <Text fontSize={12} color={"#6A6E85"}>
              {details
                .slice(0, -1)
                .map((conn) => conn.to_airport.match(/\(([^)]+)\)$/)[1])
                .join(", ")}
            </Text>
          </Td>
          <Td p={{ base: 3, lg: 4 }} border={isFlightExpanded ? "none" : ""}>
            {summaryPoints["Business"] ? (
              <Box position={"relative"}>
                {summaryPoints["Business"].mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {numberFormat.format(summaryPoints["Business"].points)}
                  <Text as="span" fontSize={10}>
                    {" "}
                    +{summaryPoints["Business"].tax_currency}
                    {Math.round(summaryPoints["Business"].tax_per_adult)}
                  </Text>
                </Text>
                <Text color={COLORS.black} fontSize="xs">
                  {summaryPoints["Business"].remaining_seats
                    ? summaryPoints["Business"].remaining_seats + " seats"
                    : flight.source === "QF" &&
                      summaryPoints["Business"].remaining_seats === null
                    ? "1+ seats"
                    : "5+ seats"}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
          <Td p={{ base: 3, lg: 4 }} border={isFlightExpanded ? "none" : ""}>
            {summaryPoints["First"] ? (
              <Box position={"relative"}>
                {summaryPoints["First"].mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {numberFormat.format(summaryPoints["First"].points)}
                  <Text as="span" fontSize={10}>
                    {" "}
                    +{summaryPoints["First"].tax_currency}
                    {Math.round(summaryPoints["First"].tax_per_adult)}
                  </Text>
                </Text>
                <Text color={COLORS.black} fontSize="xs">
                  {summaryPoints["First"].remaining_seats
                    ? summaryPoints["First"].remaining_seats + " seats"
                    : flight.source === "QF" &&
                      summaryPoints["First"].remaining_seats === null
                    ? "1+ seats"
                    : "5+ seats"}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
        </Show>

        <Show above={"lg"}>
          <Td
            p={{ base: 3, lg: 4 }}
            border={isFlightExpanded ? "none" : ""}
            fontSize={"12px"}
          >
            <Text color={COLORS.secondary}>
              {shouldIncludeAbout ? "about " : ""}
              {lastSeenText}
            </Text>
          </Td>
        </Show>
        <Show above="lg">
          <Td p={{ base: 3, lg: 4 }} border={isFlightExpanded ? "none" : ""}>
            <Popover
              placement="left"
              onOpen={() => {
                trackPage({ title: "How to Book? | QFF" })
              }}
            >
              <PopoverTrigger>
                <Image
                  src={airlineAwardImage}
                  margin="0 auto"
                  width="28px"
                  height="28px"
                  alt={airlineAwardImageAlt}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </PopoverTrigger>
              <PopoverContent
                w={{ base: 240, sm: 320 }}
                p={5}
                _focus={{ boxShadow: "none" }}
                borderRadius={8}
              >
                <PopoverBody p={0}>{bookingContentComponent}</PopoverBody>
              </PopoverContent>
            </Popover>
          </Td>
        </Show>
        <Show below="lg">
          <Td
            textTransform="none"
            textAlign="center"
            p={{ base: 3, lg: 4 }}
            w={{ base: "10%" }}
          >
            <Flex flexDirection={"column"} gap={"2px"}>
              {flight.class_details
                .filter((flightClass, index, self) => {
                  return (
                    index ===
                    self.findIndex(
                      (t) => t.designated_class === flightClass.designated_class
                    )
                  )
                })
                .map((flightClass) => {
                  const show =
                    flightClass.designated_class === "Business" ||
                    flightClass.designated_class === "First"

                  if (!show) return null

                  return (
                    <Flex
                      key={flightClass.id}
                      alignItems={"center"}
                      padding={"1px 0"}
                    >
                      <AirplaneIcon
                        color={AIRPLANE_COLORS[flightClass.designated_class]}
                      />
                      <Text fontSize={12} color={COLORS.red}>
                        <Text as={"span"} marginLeft={"6px"}>
                          {numberFormat.format(flightClass.points_per_adult)}
                        </Text>
                        <Text as={"span"} marginLeft={1}>
                          +{flightClass.tax_currency}
                          {parseInt(flightClass.tax_per_adult)}
                        </Text>
                      </Text>
                    </Flex>
                  )
                })}
            </Flex>
          </Td>
        </Show>
        <Td
          p={{ base: 3, lg: 4 }}
          border={isFlightExpanded ? "none" : ""}
          sx={{
            "& > div": {
              minWidth: "unset!important",
            },
          }}
        >
          <Popover
            placement={placement}
            closeOnBlur={true}
            onOpen={() => {
              trackPage({
                title: "Alert Route",
              })
            }}
          >
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Image
                    src={bellImage}
                    alt={`create_reward_flight_alerts_from_${flight.origin.name}_to_${flight.destination.name}`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    margin="0 auto"
                  />
                </PopoverTrigger>
                <PopoverContent
                  _focus={{ boxShadow: "none" }}
                  boxShadow="0px 10px 22px rgba(0, 0, 0, 0.14);"
                  borderRadius={8}
                  w={{ base: "100%", sm: 380 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {!!canCreateAlerts && <PopoverCloseButton />}
                  <PopoverBody p={0}>
                    {!!canCreateAlerts ? (
                      <AlertRouteContent
                        route={route}
                        originName={flight.details[0]?.from_airport || null}
                        onClose={onClose}
                        isNew={true}
                        destinationRegion={destinationRegion}
                        isMonthlyMaxOrAnnual={isMonthlyMaxOrAnnual}
                      />
                    ) : (
                      <Box>
                        <Text>{t("table.maxAlertReached")}</Text>
                      </Box>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Td>
        <Show above={"lg"}>
          <Td>
            {isFlightExpanded ? (
              <ChevronUpIcon boxSize={6} />
            ) : (
              <ChevronDownIcon boxSize={6} />
            )}
          </Td>
        </Show>
        <Show below={"lg"}>
          <Td
            textTransform={"none"}
            textAlign={"center"}
            p={{ base: 3, lg: 4 }}
            w={{ base: "10%" }}
          >
            <Flex
              alignItems={"center"}
              flexDirection={"column"}
              position={"relative"}
            >
              {hasTwoImages && (
                <Image
                  src={secondPlaneImage}
                  alt={secondAirlineImageAlt}
                  {...defaultImageStyle(hasTwoImages, 1)}
                />
              )}
              <Image
                src={planeImage}
                alt={firstAirlineImageAlt}
                {...defaultImageStyle(hasTwoImages)}
              />
            </Flex>
          </Td>
        </Show>
      </Tr>
      {isFlightExpanded && (
        <Tr
          backgroundColor={COLORS.grey}
          boxShadow={"0px 15px 18px 0px rgba(20, 23, 37, 0.13)"}
          position={"relative"}
          zIndex={1}
        >
          <Td colSpan={11} p={{ base: 1, lg: 2 }} border={0}>
            <ExpandableRow flight={flight} />
          </Td>
        </Tr>
      )}
    </Fragment>
  )
}

export default FlightRow
