import { Flex, Image, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import noAlerts from "../../../img/no-alerts.png"

const NoAlertsGuidance = () => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      width={"100%"}
      padding={{ base: "20px", md: "40px 0 40px 40px" }}
      boxShadow="0px 2px 8px rgba(20, 23, 37, 0.08)"
      borderRadius={"12px"}
      gap={10}
      justifyContent={"space-between"}
    >
      <Flex
        flex={"0 1 42%"}
        flexDirection={"column"}
        gap={5}
        textAlign={"left"}
      >
        <Text fontSize={"24px"} fontWeight={700}>
          {t("alerts.noAlerts.title")}
        </Text>

        <Text>{t("alerts.noAlerts.description1")}</Text>
        <Text>{t("alerts.noAlerts.description2")}</Text>
      </Flex>

      <Flex flex={"0 1 58%"}>
        <Image
          src={noAlerts}
          width={"100%"}
          height={"auto"}
          objectFit={"contain"}
        />
      </Flex>
    </Flex>
  )
}

export default NoAlertsGuidance
