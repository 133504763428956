import { Button, Flex, Heading, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { COLORS } from "../../../constants/constants"
import AdditionalUserInfo from "./AdditionalUserInfo"
import EditUserDetails from "./EditUserDetails"
import UserDetails from "./UserDetails"

const PersonalDetails = ({ user }) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleCloseEdit = () => {
    setIsEditing(false)
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justifyContent={"space-between"} alignItems={"center"} height={9}>
        <Heading
          as={"h1"}
          color={COLORS.black}
          textAlign={"left"}
          fontSize={{ base: "xl", lg: "2xl" }}
        >
          {t("settings.header")}
        </Heading>

        {!isEditing && (
          <Button
            type={"button"}
            onClick={handleEditClick}
            backgroundColor={COLORS.red}
            color={COLORS.white}
            textTransform={"uppercase"}
            height={"100%"}
          >
            <Text fontSize={"14px"}>{t("settings.editDetails")}</Text>
          </Button>
        )}
      </Flex>

      <Flex
        backgroundColor={COLORS.white}
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
        padding={6}
        gap={5}
        textAlign={"left"}
        borderRadius={"12px"}
        boxShadow={"0px 1px 2px rgba(0, 0, 0, 0.1)"}
      >
        {!isEditing ? (
          <UserDetails user={user} />
        ) : (
          <EditUserDetails user={user} handleCloseEdit={handleCloseEdit} />
        )}

        <AdditionalUserInfo user={user} />
      </Flex>
    </Flex>
  )
}

export default PersonalDetails
