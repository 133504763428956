import { Helmet } from "react-helmet-async"

export const mainPageSeoTags = ({ flightsTexts, route, isRequested }) => {
  if (isRequested && route && flightsTexts?.from && flightsTexts?.to) {
    const { from, to } = flightsTexts
    const title = `Qantas and Velocity Reward Flights to ${to.fullName} from ${from.fullName}`
    const link = `https://gyozaflights.com/reward-flights/${route}`
    const metaTitle = `Qantas Reward Flights to ${to.fullName} | Gyoza Flights`
    const metaDescription = `All reward flights from ${from.fullName} to ${to.fullName} including Qantas Classic Flight Rewards and Velocity reward points.`
    const metaKeywords = `Qantas reward flights to ${to.name}, ${from.code} to ${to.code} reward flights, Velocity reward flights to ${to.name}, Qantas frequent flyer points, Velocity reward points, ${from.name} to ${to.name} upgrades, ${from.name} to ${to.name} unsold seats, ${from.name} to ${to.name} flight availability`
    const metaOgTitle = `Qantas Reward Flights to ${to.fullName} from ${from.fullName} | Gyoza Flights`
    const metaOgDescription = `All reward flights from ${from.fullName} to ${to.fullName} including Qantas Classic Flight Rewards and Velocity reward points.`
    const metaOgUrl = `https://gyozaflights.com/reward-flights/${route}`

    return (
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={link} />
        <meta property="title" content={metaTitle} data-rh="true" />
        <meta property="description" content={metaDescription} data-rh="true" />
        <meta property="keywords" content={metaKeywords} data-rh="true" />{" "}
        <meta property="og:title" content={metaOgTitle} data-rh="true" />
        <meta
          property="og:description"
          content={metaOgDescription}
          data-rh="true"
        />
        <meta property="og:url" content={metaOgUrl} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>{"Gyoza Flights | Qantas and Velocity Reward Flights"}</title>

      <link rel="canonical" href={"https://gyozaflights.com/"} />

      <meta
        name="title"
        content={"Gyoza | Qantas Reward Flights and Velocity Reward Flights"}
        data-rh="true"
      />
      <meta
        name="description"
        content={
          "All Qantas and Velocity reward flights in one place. Search available Classic Reward Flights and Velocity flights with the Gyoza reward flight finder"
        }
        data-rh="true"
      />
      <meta
        name="keywords"
        content={
          "Qantas Reward Flights, Classic Flight Rewards, Velocity Reward Flights, Qantas Frequent Flyer"
        }
        data-rh="true"
      />

      <meta
        property="og:title"
        content={"Gyoza | Qantas Reward Flights and Velocity Reward Flights"}
        data-rh="true"
      />
      <meta
        property="og:description"
        content={
          "All Qantas and Velocity reward flights in one place. Search available Classic Reward Flights and Velocity flights with the Gyoza reward flight finder"
        }
        data-rh="true"
      />
      <meta property="og:url" content={"https://gyozaflights.com/"} />
    </Helmet>
  )
}

export const amadeusPageSeoTags = ({ flightsTexts, route }) => {
  if (!flightsTexts) {
    return (
      <Helmet>
        <title>
          Gyoza Flights | Flight Availability, Flight Upgrades, Unsold Seats,
          Passenger Load
        </title>
        <meta
          name="title"
          content="Flight Availability, Upgrades, Unsold Seats, Flight Loads | Gyoza"
        />
        <meta
          name="description"
          content="Upgrade your flight to Business or First Class by seeing all unsold seats in real time. Choose flights with low passenger loads to increase upgrade chances"
        />
        <link
          rel="canonical"
          href="https://gyozaflights.com/flight-availability"
        />
        <meta
          name="keywords"
          content="Flight Availability, Passenger Loading, Upgrade Likelihood, Unsold Fares, Business Class upgrade, Flight Loading"
        />
        <meta
          property="og:title"
          content="Gyoza | Reward Flights, Flight Availability and Upgrades"
        />
        <meta
          property="og:description"
          content="Upgrade your flight to Business or First Class by seeing all unsold seats in real time. Choose flights with low passenger loads to increase upgrade chances"
        />
        <meta
          property="og:url"
          content="https://gyozaflights.com/flight-availability"
        />
      </Helmet>
    )
  }

  if (flightsTexts?.from && flightsTexts?.to) {
    const { from, to } = flightsTexts

    return (
      <Helmet>
        <title>
          {`Flight Availability from ${from.fullName} to ${to.fullName}`}
        </title>
        <meta
          name="title"
          content={`Flight Availability from ${from.fullName} to ${to.fullName} | Gyoza Flights`}
        />
        <meta
          name="description"
          content={`Check your upgrade chances on flights between ${from.fullName} and ${to.fullName} by searching all unsold Business Class and First Class seats in real time`}
        />
        <meta
          name="keywords"
          content={`${from.name} to ${to.name} flight availability, ${from.code} to ${to.code} flight availability, ${from.name} to ${to.name} upgrades, ${from.name} to ${to.name} unsold seats, ${from.name} to ${to.name} passenger loading`}
        />
        <meta
          property="og:title"
          content={`Flight Availability from ${from.fullName} to ${to.fullName} | Gyoza Flights`}
        />
        <meta
          property="og:description"
          content={`Check your upgrade chances on flights between ${from.fullName} and ${to.fullName} by searching all unsold Business Class and First Class seats in real time`}
        />
        <meta
          property="og:url"
          content={`https://gyozaflights.com/flight-availability/${route}`}
        />
        <link
          rel="canonical"
          href={`https://gyozaflights.com/flight-availability/${route}`}
        />
      </Helmet>
    )
  }
}

export const toFromAustraliaSeoTags = () => (
  <Helmet>
    <title>Business Class and First Class Flight Finder</title>
    <meta
      name="title"
      content="Search Business and First Class Reward Seats | Gyoza Flights"
    />
    <meta
      name="description"
      content="Quickly search for Business and First Class Reward seats leaving Australia and reward flights back to Australia."
    />
    <meta
      name="keywords"
      content="business class reward seats, first class reward seats"
    />
    <meta
      property="og:title"
      content="Search Business and First Class Reward Seats | Gyoza Flights"
    />
    <meta
      property="og:description"
      content="Quickly search for Business and First Class Reward seats leaving Australia and reward flights back to Australia."
    />
    <meta
      property="og:url"
      content="https://gyozaflights.com/business-class-reward-flights"
    />
    <link
      rel="canonical"
      href="https://gyozaflights.com/business-class-reward-flights"
    />
  </Helmet>
)
