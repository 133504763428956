import { useEffect } from "react"

const useDebugger = () => {
  useEffect(() => {
    let intervalId = null

    const callInterval = () => {
      try {
        intervalId = setInterval(() => {
          ;(function () {
            return !1
          })
            .constructor("debugger")
            .call()
        }, 100)
      } catch (p) {}
    }

    callInterval()

    return () => {
      clearInterval(intervalId)
    }
  }, [])
}

export default useDebugger
