import { Fragment } from "react"
import { format, parseISO, formatDuration, differenceInMinutes } from "date-fns"
import { Text, Image, Flex, Box } from "@chakra-ui/react"

import {
  COLORS,
  DATE_FORMAT_EXPANDABLE_ROW,
} from "../../../constants/constants"

import flightImages from "../../../flightImages"

import airplane from "../../../img/airplane.svg"

const parseDate = (dateStr) => {
  return parseISO(dateStr)
}

const ExpandableRow = ({ flight }) => {
  return (
    <Box>
      {flight.details.map((detail, index, details) => {
        const planeImage =
          details.length >= 3
            ? flightImages("group_3_plus")
            : flightImages(details[index].aircraft_code)

        let layoverTime = ""
        if (index > 0) {
          const previousArrival = parseISO(details[index - 1].arrival_date)
          const currentDeparture = parseISO(detail.departure_date)
          const difference = differenceInMinutes(
            currentDeparture,
            previousArrival
          )
          layoverTime = formatDuration({
            hours: Math.floor(difference / 60),
            minutes: difference % 60,
          })
        }

        return (
          <Fragment key={index}>
            {layoverTime && (
              <Text
                ml={"42px"}
                fontSize="12px"
                color={COLORS.secondary}
                borderY={"1px"}
                borderColor={COLORS.secondary}
                borderStyle={"solid"}
                width={"min-content"}
                whiteSpace={"nowrap"}
                py={"4px"}
              >
                Layover: {layoverTime}
              </Text>
            )}
            <Flex my={3} fontSize="sm" fontWeight="semibold">
              <Flex alignItems="center" gap="10px" w="36px">
                <Image
                  width="100%"
                  src={planeImage}
                  alt={`upgrade_${detail.from_airport}_flight_to_${detail.to_airport}`}
                  margin="0 auto"
                  position="relative"
                  zIndex={1}
                />
              </Flex>

              <Image
                src={airplane}
                width="24px"
                height="108px"
                alt={`reward_flight_duration_between_${detail.from_airport}_and_${detail.to_airport}`}
                alignSelf="center"
                mr="2px"
              />

              <Box w={"45%"} fontSize="12px">
                <Text color={COLORS.secondary}>
                  {format(
                    parseDate(detail.departure_date),
                    DATE_FORMAT_EXPANDABLE_ROW
                  )}
                </Text>
                <Text>{detail.from_airport}</Text>
                <Text color={COLORS.secondary} my={5} fontSize={"xs"}>
                  {detail.flight_duration}
                </Text>
                <Text color={COLORS.secondary}>
                  {format(
                    parseDate(detail.arrival_date),
                    DATE_FORMAT_EXPANDABLE_ROW
                  )}
                </Text>
                <Text>{detail.to_airport}</Text>
              </Box>

              <Box w={"45%"} fontSize={"12px"}>
                <Text color={COLORS.secondary}>
                  Flight: {detail.aircraft_details}
                </Text>
                {detail?.equipment && (
                  <Text color={COLORS.secondary}>
                    Aircraft: {detail.equipment}
                  </Text>
                )}
                <Text
                  color={COLORS.secondary}
                  display={"flex"}
                  columnGap={"4px"}
                >
                  <Text as="span" whiteSpace={"nowrap"}>
                    All unsold fares:
                  </Text>
                  <Text as="span">
                    {detail.all_seats.join(", ").replace(/,\s*$/, "")}
                  </Text>
                </Text>
              </Box>
            </Flex>
          </Fragment>
        )
      })}
    </Box>
  )
}

export default ExpandableRow
