import { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { Box, Stack, Flex, useDisclosure } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import "react-datepicker/dist/react-datepicker.css"
import { addMonths, subDays } from "date-fns"

import { Select } from "../Select"

import {
  CustomCalendarContainer,
  DatePickerInput,
  RenderCustomHeader,
  RenderDayContents,
} from "./components/DatePickerProps.js"
import { SUBSCRIPTION } from "../../constants/constants.js"
import { getPricingPlans } from "../../services/api.js"

const { FREE } = SUBSCRIPTION

const FilterPanel = ({
  from,
  to,
  date,
  onChange,
  user,
  originAirportsQuery,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef(null)
  const {
    isOpen: showPopover,
    onOpen: onOpenPopover,
    onClose: onClosePopover,
  } = useDisclosure()

  const isFreePlan =
    !user || [FREE, null].includes(user?.subscription.toLowerCase())

  useOutsideAlerter(wrapperRef, () => setIsOpen(false))

  function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick()
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, onOutsideClick])
  }

  const handleClosePicker = () => {
    onChange({ date: null })
    setIsOpen(false)
  }
  const handleTogglePicker = () => {
    if (isFreePlan) {
      setIsOpen(false)
      onOpenPopover()
    } else {
      if (to) {
        setIsOpen(!isOpen)
      }
    }
  }

  const handleDateChange = (date) => {
    onChange({ date })
    setIsOpen(false)
  }

  const { data: pricingPlans } = useQuery({
    queryKey: ["pricingPlans"],
    queryFn: getPricingPlans,
    initialData: [],
  })

  const today = new Date()
  const oneYearLater = subDays(addMonths(today, 12), 5)

  const sortedOriginAirports = originAirportsQuery.data.sort((a, b) =>
    a.name.trim().localeCompare(b.name.trim())
  )

  const originAirportOptions = sortedOriginAirports.map((airport) => {
    return {
      value: airport.code,
      city: airport.city,
      label: `${airport.city.trim()} (${airport.code})`,
      fullLabel: `${airport.name} (${airport.code})`,
      searchBy: `${airport.name} (${airport.code}) ${airport.city}`,
    }
  })

  const destinationAirportOptions = sortedOriginAirports
    .filter((airport) => airport.code !== from?.value)
    .map((airport) => ({
      value: airport.code,
      city: airport.city,
      label: `${airport.city.trim()} (${airport.code})`,
      fullLabel: `${airport.name} (${airport.code})`,
      searchBy: `${airport.name} (${airport.code}) ${airport.city}`,
    }))

  return (
    <Box>
      <Stack direction={{ base: "column", lg: "row" }} gap={3} mb={4}>
        <Flex
          gap={3}
          display={"flex"}
          direction={{ base: "column", lg: "row" }}
        >
          <Box bg={"#fff"} rounded={"12px"} w={{ xl: 220 }}>
            <Select
              placeholder="Where from?"
              labelLeftText={"From:"}
              onChange={(value) => onChange({ from: value, date: null })}
              value={from}
              options={originAirportOptions}
              isSearchable={true}
              focused={true}
              showArrow={false}
            />
          </Box>

          <Box bg={"#fff"} rounded={"12px"} w={{ xl: 220 }}>
            <Select
              placeholder="Where to?"
              labelLeftText={"To:"}
              onChange={(value) => onChange({ to: value, date: null })}
              value={to}
              options={destinationAirportOptions}
              isSearchable={true}
              isDisabled={!from}
              showArrow={false}
            />
          </Box>
        </Flex>

        <Flex
          gap={3}
          marginInlineStart={0}
          width={["100%", "100%", "100%", "200px", "100%"]}
          ref={wrapperRef}
        >
          <Flex
            bg={"#fff"}
            rounded={"12px"}
            justify="center"
            align="center"
            w={"100%"}
            sx={{
              "@media (max-width: 767px)": {
                "& .react-datepicker-popper": {
                  inset: "auto!important",
                  transform: "none!important",
                  position: "fixed!important",
                  left: "0!important",
                  bottom: "0!important",
                  width: "100%",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "flex-end",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  paddingTop: "0px!important",
                  "> div": {
                    overflowY: "auto",
                    width: "100%",
                    maxHeight: "100%",
                  },
                },
              },
            }}
          >
            <DatePicker
              open={isOpen}
              onChange={handleDateChange}
              disabled={!to}
              selected={date}
              startDate={date}
              customInput={
                <DatePickerInput
                  handleTogglePicker={handleTogglePicker}
                  showPopover={showPopover}
                  onClosePopover={onClosePopover}
                  pricingPlans={pricingPlans}
                />
              }
              dateFormat="EEE, dd MMMM"
              minDate={today}
              maxDate={oneYearLater}
              renderDayContents={(day, otherProps) => (
                <RenderDayContents day={day} {...otherProps} />
              )}
              renderCustomHeader={(props) => <RenderCustomHeader {...props} />}
              calendarContainer={(props) => (
                <CustomCalendarContainer
                  handleClosePicker={handleClosePicker}
                  {...props}
                />
              )}
            />
          </Flex>
        </Flex>
      </Stack>
    </Box>
  )
}

export default FilterPanel
