import { useContext } from "react"
import FlightDataContext from "./FlightDataContext"
import FlightsTable from "./FlightsTable/FlightsTable"

const FlightDetail = () => {
  const { flights, isFetching, user } = useContext(FlightDataContext)

  return <FlightsTable flights={flights} isFetching={isFetching} user={user} />
}

export default FlightDetail
