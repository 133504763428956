import React from "react"
import { Flex, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import flightsNotFound from "../img/flights_not_found.svg"

const FlightsNotFound = ({ text }) => {
  const { t } = useTranslation()

  return (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <Image
        src={flightsNotFound}
        width={"98px"}
        height={"98px"}
        alt={"not found"}
      />
      <Text p={2} color={"#6A6E85"}>
        {t(text)}
      </Text>
    </Flex>
  )
}

export default FlightsNotFound
