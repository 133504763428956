import React, { useState } from "react"
import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { format, parseISO } from "date-fns"

import {
  COLORS,
  DATE_FORMAT_LIKELIHOOD_POPOVER,
} from "../../../../constants/constants"

import { ReactComponent as PopoverCircle } from "../../../../img/popover_circle.svg"
import likelihood_airplane from "../../../../img/likelihood_airplane.svg"

const parseDate = (dateStr) => {
  return parseISO(dateStr)
}

const PopoverTop = ({
  details,
  planeImages,
  aircrafts,
  flightDetail,
  popoverFlightsTexts,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Flex
      width={"100%"}
      padding={"60px"}
      justifyContent={"space-between"}
      backgroundColor={COLORS.grey}
      gap={10}
    >
      <Flex flex={"1 1 auto"} gap={"42px"}>
        <Flex flexDirection={"column"} gap={4}>
          <PopoverCircle />
        </Flex>
        <Flex flexDirection={"column"} gap={"24px"}>
          <Flex flexDirection={"column"} lineHeight={"32px"}>
            {aircrafts.map((aircraft, index) => (
              <Text key={index} fontSize="24px" fontWeight={"700"}>
                {aircraft.name}{" "}
                <Text as={"span"} color={COLORS.red}>
                  {aircraft.code}
                </Text>
              </Text>
            ))}
          </Flex>
          <Flex>
            <Image
              src={likelihood_airplane}
              width="32px"
              height="125px"
              alt={`reward_flight_duration_between_${flightDetail.from_airport}_and_${flightDetail.to_airport}`}
              alignSelf="center"
              mr={5}
            />

            <Box>
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={COLORS.black}
                marginBottom={1}
              >
                {format(
                  parseDate(details[activeTab].departure_date),
                  DATE_FORMAT_LIKELIHOOD_POPOVER
                )}
              </Text>
              <Text fontSize={"20px"} fontWeight={700}>
                {popoverFlightsTexts[activeTab].from.fullName}
              </Text>
              <Text color={COLORS.secondary} my={5} fontSize={"xs"}>
                {details[activeTab].flight_duration}
              </Text>
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={COLORS.black}
                marginBottom={1}
              >
                {format(
                  parseDate(details[activeTab].arrival_date),
                  DATE_FORMAT_LIKELIHOOD_POPOVER
                )}
              </Text>
              <Text fontSize={"20px"} fontWeight={700}>
                {popoverFlightsTexts[activeTab].to.fullName}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection={"column"} gap={4}>
        {planeImages.map((planeImage, index) => (
          <Box
            key={index}
            borderRadius={"18px"}
            backgroundColor={"#fff"}
            padding={"16px 9px 16px 13px"}
            height={"fit-content"}
            boxShadow="0px 5.25px 21px 0px rgba(0, 0, 0, 0.1)"
            border={`1px solid ${activeTab === index ? "#DD0000" : "#FFFFFF"}`}
            onClick={() => setActiveTab(index)}
          >
            <Image src={planeImage.image} alt={planeImage.alt} width={"40px"} />
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}

export default PopoverTop
