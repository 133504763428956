import React from "react"
import { Box, Image } from "@chakra-ui/react"

import loadingAnimation from "../img/loading_animation.svg"

const LoadingAnimation = () => (
  <Box>
    <Image src={loadingAnimation} mx={"auto"} alt={"loading"} />
  </Box>
)

export default LoadingAnimation
