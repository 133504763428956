import { Button, Flex, Input, Text, useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"
import ChangeEmailModal from "./ChangeEmailModal"

const UpdateUserEmail = ({ user }) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [successText, setSuccessText] = useState(null)

  const onEmailChangeSuccess = () => {
    setSuccessText(t("validation.emailSuccessText"))
  }
  return (
    <>
      <ChangeEmailModal
        isOpen={isOpen}
        onClose={onClose}
        onEmailChangeSuccess={onEmailChangeSuccess}
      />

      <Flex gap={"6px"} flexDirection={"column"}>
        <Text
          fontSize={"xs"}
          fontWeight={"semibold"}
          whiteSpace={"nowrap"}
          m={0}
        >
          {t("login.email")}:
        </Text>

        <Flex flexDirection={"column"}>
          <Flex height={9} gap={1}>
            <Input
              width={"auto"}
              height={"100%"}
              value={user.email}
              type={"email"}
              bg={COLORS.white}
              isReadOnly={true}
            />

            <Button
              type={"button"}
              onClick={onOpen}
              height={"100%"}
              fontSize={"14px"}
              fontWeight={700}
              color={COLORS.red}
              textTransform={"uppercase"}
            >
              Reset email
            </Button>
          </Flex>

          {successText && (
            <Text color={COLORS.green} fontWeight={600} fontSize={"12px"}>
              {successText}
            </Text>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default UpdateUserEmail
