function flightImages(searchString) {
  const context = require.context(
    "./img/airline_logos/",
    false,
    /\.(png|jpg|jpeg)$/
  )

  if (searchString === "group_3_plus") {
    return context("./group_3_plus.png")
  }

  for (const filename of context.keys()) {
    const filenameLower = filename.toLowerCase()
    const searchStringLower = searchString.toLowerCase()

    const firstTwoLetters = filenameLower.slice(2, 4)

    if (firstTwoLetters === searchStringLower) {
      const matchedImage = context(filename)

      return matchedImage
    }
  }

  return null
}

export default flightImages
