import React from "react"
import { Box, Flex, Image } from "@chakra-ui/react"

import prevArrow from "../../../img/prev_arrow_icon.svg"
import nextArrow from "../../../img/next_arrow_icon.svg"

const PrevNextArrows = ({ handleScroll }) => {
  return (
    <Flex
      width={"100%"}
      justifyContent={"space-between"}
      position="absolute"
      padding={"0 6px"}
      left="50%"
      top="50%"
      transform="translateX(-50%)"
      zIndex="9"
    >
      <Box
        onClick={() => handleScroll("left")}
        cursor="pointer"
        backgroundColor="#FFFFFF"
        width="40px"
        height="40px"
        borderRadius="50%"
        boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        userSelect={"none"}
      >
        <Image src={prevArrow} alt="previous" />
      </Box>
      <Box
        onClick={() => handleScroll("right")}
        cursor="pointer"
        backgroundColor="#FFFFFF"
        width="40px"
        height="40px"
        borderRadius="50%"
        boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        userSelect={"none"}
      >
        <Image src={nextArrow} alt="next" />
      </Box>
    </Flex>
  )
}

export default PrevNextArrows
