import { CalendarContainer } from "react-datepicker"

export const CustomCalendarContainer = ({ className, children }) => {
  return (
    <div
      style={{ background: "#fff" }}
      className="datepicker-wrapper alert-wrapper"
    >
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </div>
  )
}
