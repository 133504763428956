import React from "react"
import { useQuery } from "@tanstack/react-query"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

import Post from "./Post/Post"
import { getPosts } from "../../services/api"
import { COLORS } from "../../constants/constants"

const GyozaFlightSearchHeader = () => {
  const postsQuery = useQuery({
    queryKey: ["posts"],
    queryFn: getPosts,
    initialData: [],
  })

  return (
    <Box display={{ base: "none", lg: "block" }} mb={"40px"}>
      <Box px={[4, 0]} pb="30px">
        <Heading
          as="h2"
          align="left"
          pb="2"
          color={COLORS.black}
          fontSize={{ base: "xl", lg: "2xl" }}
        >
          {postsQuery?.data[0]?.title}
        </Heading>
        <Text
          align="left"
          color={COLORS.black}
          fontSize={{ base: "small", lg: "sm" }}
        >
          {postsQuery?.data[0]?.description}
        </Text>
      </Box>

      <Flex gap={"30px"}>
        {postsQuery?.data[0]?.posts.map((post) => (
          <Post key={post.id} {...post} />
        ))}
      </Flex>
    </Box>
  )
}

export default GyozaFlightSearchHeader
