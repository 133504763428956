export const trackPage = ({ title, ...other }) => {
  if (process.env.NODE_ENV !== "production") {
    window.gtag("event", "page_view", {
      page_title: title,
      ...other,
    })
  }
}

export const handleTrackSearch = ({ origin, destination }) => {
  window.gtag("event", "route_search", {
    origin: origin.name,
    destination: destination.name,
  })
}

export const handleTrackAirlineButton = ({ name }) => {
  window.gtag("event", "button_redirect", {
    airline_name: name,
    button_name: "Book",
    section_name: "How to Book?",
  })
}
