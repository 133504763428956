import React from "react"
import { Flex } from "@chakra-ui/react"

import PopoverTop from "./PopoverTop"
import PopoverBottom from "./PopoverBottom"

const Popover = ({
  planeImages,
  details,
  aircraftCodes,
  flightDetail,
  popoverFlightsTexts,
}) => {
  const aircrafts = aircraftCodes.map((aircraftCode) => {
    let name, code

    if (aircraftCode.includes("Airline code:")) {
      const parts = aircraftCode.split("Airline code:")
      name = parts[0].trim()
      code = parts[1].trim()
    } else {
      const parts = aircraftCode.split(" ")
      name = parts.slice(0, -1).join(" ").trim()
      code = parts.pop().trim()
    }

    return { name: name, code: code }
  })

  const handleClick = (e) => {
    e.stopPropagation()
  }

  return (
    <Flex
      width={"100vw"}
      maxWidth={"866px"}
      flexDirection={"column"}
      borderRadius={"50px"}
      overflow={"hidden"}
      backgroundColor={"white"}
      onClick={handleClick}
    >
      <PopoverTop
        details={details}
        planeImages={planeImages}
        aircrafts={aircrafts}
        flightDetail={flightDetail}
        popoverFlightsTexts={popoverFlightsTexts}
      />
      <PopoverBottom
        details={details}
        flightDetail={flightDetail}
        aircraftCode={aircraftCodes[0]}
        code={aircrafts[0]?.code}
      />
    </Flex>
  )
}

export default Popover
