import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons"
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { getPagesToRender } from "../helpers/functions"

const Pagination = ({ page, totalPages, onPrev, onNext, onCurrent }) => {
  const { t } = useTranslation()
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const isJumpForwardPossible = page + 50 < totalPages
  const isJumpBackPossible = page - 50 > 0

  const paginationButtons = getPagesToRender(page, totalPages)

  const setPage = (page) => onCurrent(page)

  if (!totalPages) {
    return
  }

  return (
    <Flex
      py={5}
      justify={"center"}
      align={"baseline"}
      gap={2}
      userSelect={"none"}
    >
      {isDesktop && isJumpBackPossible && (
        <Flex
          cursor={"pointer"}
          direction={"column"}
          onClick={() => setPage(page - 50)}
        >
          <Text>
            <ArrowLeftIcon color="#70767D" boxSize={3} />
          </Text>
          <Text
            color={"#70767D"}
            cursor={"pointer"}
            px={2}
            py={"1px"}
            borderRadius={7}
            fontSize={14}
          >
            {t("jumpBack50")}
          </Text>
        </Flex>
      )}
      <Flex align={"baseline"} gap={2}>
        <Text onClick={onPrev} cursor={page === 1 ? "not-allowed" : "pointer"}>
          <ChevronLeftIcon color="#70767D" boxSize={6} />
        </Text>
        {paginationButtons.map((label, index) => {
          return (
            <Text
              key={`${label}${index}`}
              onClick={() => setPage(label)}
              backgroundColor={label === page ? "#FF0000" : "none"}
              color={label === page ? "white" : "#70767D"}
              cursor={[page, "..."].includes(label) ? "default" : "pointer"}
              px={2}
              py={"1px"}
              borderRadius={7}
              fontSize={14}
            >
              {label}
            </Text>
          )
        })}
        <Text
          onClick={onNext}
          cursor={page === totalPages ? "not-allowed" : "pointer"}
        >
          <ChevronRightIcon color="#70767D" boxSize={6} />
        </Text>
      </Flex>
      {isDesktop && isJumpForwardPossible && (
        <Flex
          cursor={"pointer"}
          direction={"column"}
          onClick={() => setPage(page + 50)}
        >
          <Text>
            <ArrowRightIcon color="#70767D" boxSize={3} />
          </Text>
          <Text
            color={"#70767D"}
            cursor={"pointer"}
            px={2}
            py={"1px"}
            borderRadius={7}
            fontSize={14}
          >
            {t("jumpForward50")}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
export default Pagination
