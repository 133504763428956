import { Fragment } from "react"

import { Text, Image, Flex, Box } from "@chakra-ui/react"

import flightImages from "../../../flightImages"
import airplane from "../../../img/airplane.svg"
import {
  COLORS,
  DATE_FORMAT_EXPANDABLE_ROW,
} from "../../../constants/constants"
import { formatUTCDate } from "../../../helpers/functions"

const ExpandableRow = ({ flight }) => {
  const showFlightClasses = (flightClass, index) => {
    const uniqueFlightClasses = flightClass.map(({ cabin_type }) => {
      const classArr = cabin_type.split(", ")

      return classArr[index]
    })

    const sortOrder = ["Economy", "PremiumEconomy", "Business", "First"]

    uniqueFlightClasses.sort((a, b) => {
      const indexA = sortOrder.indexOf(a)
      const indexB = sortOrder.indexOf(b)
      if (indexA === -1) return 1 // If not found, place at the end
      if (indexB === -1) return -1 // If not found, place at the end
      return indexA - indexB
    })

    return uniqueFlightClasses.join(", ")
  }

  return (
    <Box>
      {flight.details.map((detail, index, details) => {
        const planeImage =
          details.length >= 3
            ? flightImages("group_3_plus")
            : flightImages(details[index].aircraft_details.slice(0, 2))

        return (
          <Fragment key={index}>
            <Flex my={6} fontSize="sm" fontWeight="semibold">
              <Flex alignItems="center" gap="10px" w="36px">
                <Image
                  width="100%"
                  src={planeImage}
                  margin="0 auto"
                  position="relative"
                  zIndex={1}
                />
              </Flex>

              <Image
                src={airplane}
                width="24px"
                height="108px"
                alt={`reward_flight_duration_between_${flight.origin.name}_and_${flight.destination.name}`}
                alignSelf="center"
                mr="2px"
              />

              <Box w={"45%"} fontSize="12px">
                <Text color={COLORS.secondary}>
                  {formatUTCDate(
                    detail.departure_date,
                    DATE_FORMAT_EXPANDABLE_ROW
                  )}
                </Text>
                <Text>{detail.from_airport}</Text>
                <Text color={COLORS.secondary} my={5} fontSize={"xs"}>
                  {detail.flight_duration}
                </Text>
                <Text color={COLORS.secondary}>
                  {formatUTCDate(
                    detail.arrival_date,
                    DATE_FORMAT_EXPANDABLE_ROW
                  )}
                </Text>
                <Text>{detail.to_airport}</Text>
              </Box>

              <Box w={"45%"} fontSize={"12px"}>
                <Text color={COLORS.secondary}>
                  Flight: {detail.aircraft_details}
                </Text>
                <Text color={COLORS.secondary}>
                  Availability: {showFlightClasses(flight.class_details, index)}
                </Text>
                {detail?.equipment && (
                  <Text color={COLORS.secondary}>
                    Aircraft: {detail.equipment}
                  </Text>
                )}
              </Box>
            </Flex>
            {index < details.length - 1 && (
              <Box
                ml="60px"
                py={2}
                borderTop="1px solid #D4D4D9;"
                borderBottom="1px solid #D4D4D9;"
                fontWeight="semibold"
                fontSize={"12px"}
              >
                <Text>
                  Layover: {detail.to_airport} {detail.transition_time}
                </Text>
              </Box>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export default ExpandableRow
