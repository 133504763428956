import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialPage = parseInt(searchParams.get("page") || "1", 10)
  const [page, setPage] = useState(initialPage)

  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10)
    setPage(currentPage)
  }, [searchParams])

  const goToPrevPage = () => {
    if (page > 1) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set("page", page - 1)

      setSearchParams(newSearchParams)
      setPage((prevState) => (prevState > 1 ? prevState - 1 : prevState))
    }
  }

  const goToNextPage = (totalPages) => {
    if (page < totalPages) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set("page", page + 1)

      setSearchParams(newSearchParams)
      setPage((prevState) =>
        prevState < totalPages ? prevState + 1 : prevState
      )
    }
  }

  const setCurrentPage = (page) => {
    if (page !== "...") {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set("page", page)

      setSearchParams(newSearchParams)
      setPage(page)
    }
  }

  return {
    page,
    onPrev: goToPrevPage,
    onNext: goToNextPage,
    onCurrent: setCurrentPage,
  }
}

export default usePagination
