import React from "react"
import { Box } from "@chakra-ui/react"

import Layout from "./Layout"
import { COLORS } from "../constants/constants"

const ErrorFallback = () => {
  return (
    <Layout>
      <Box
        bg={COLORS.grey}
        px={[0, "30px"]}
        py="7"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        marginInlineStart={0}
        flexGrow={1}
      >
        <p>
          Oops! Something went wrong. Please try refreshing the page or contact
          support if the problem persists.
        </p>

        <div
          style={{
            margin: "12px 0",
            borderRadius: "12px",
            backgroundColor: COLORS.red,
            color: "#fff",
            width: "fit-content",
            padding: "8px 16px",
          }}
        >
          <a href="/">Go Home</a>
        </div>
      </Box>
    </Layout>
  )
}

export default ErrorFallback
