import { Flex, Heading, Image, Text } from "@chakra-ui/react"
import { Trans, useTranslation } from "react-i18next"
import checkedIcon from "../../../img/checkedIcon.svg"

const strategySections = [
  {
    id: 1,
    text: "amadeus.strategy.section1",
  },
  {
    id: 2,
    text: "amadeus.strategy.section2",
  },
  {
    id: 3,
    text: "amadeus.strategy.section3",
  },
  {
    id: 4,
    text: "amadeus.strategy.section4",
  },
]

const StrategiesForUpdating = ({ flightsTexts }) => {
  const { t } = useTranslation()
  const { from, to } = flightsTexts

  return (
    <Flex
      flexDirection={"column"}
      gap={"30px"}
      padding={"30px"}
      backgroundColor={"white"}
      borderRadius={"12px"}
      textAlign={"left"}
    >
      <Heading as={"h2"} fontSize="30px" textAlign={"left"}>
        {t("amadeus.strategy.title", { from: from.name, to: to.name })}
      </Heading>

      <Flex
        flexDirection={"column"}
        gap={"20px"}
        borderRadius={"12px"}
        border={"1px solid #D3EBCB"}
        padding={"20px"}
      >
        {strategySections.map(({ id, text }) => (
          <Flex key={id} flexDirection={"column"} gap={"20px"}>
            <Flex alignItems={"center"} gap={"20px"}>
              <Image
                src={checkedIcon}
                width="40px"
                height="40px"
                alt="checked icon"
              />
              <Text fontSize={"14px"}>
                <Trans
                  i18nKey={text}
                  values={{ to: to.name }}
                  components={{ bold: <strong /> }}
                />
              </Text>
            </Flex>
            {id !== 4 && (
              <div style={{ height: "1px", backgroundColor: "#D3EBCB" }} />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default StrategiesForUpdating
